.row-kanban {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.card-item {
    font-size: 15px;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
    z-index: 1;
    background-color: white;
}

.card-item:hover {
    cursor: pointer;
}

.card-item-title {
    font-weight: 600;
    font-size: 16px;
}

.card-item-status {
    text-align: right;
}

.color-bar {
    width: 40px;
    height: 10px;
    border-radius: 5px;
}

.drop-wrapper {
    flex: 1 25%;
    width: 100%;
    height: 100%;
}

.col-wrapper {
    display: flex;
    flex-direction: column;
    margin: 20px;
    padding: 20px;
    background-color: #f5eaea;
    border-radius: 5px;
}

.col-header {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
    margin-top: 0;
}

.kanban-col {
    min-height: 300px;
    max-width: 300px;
    width: 300px;
}

.highlight-region {
    background-color: yellow;
}

.page-header {
    background-color: #054F7C;
    padding: 20px;
    color: white;
    font-size: 30px;
    flex: 1 100%;
    margin-top: 0;
    text-align: center;
}

.modal {
    background-color: #F4F5F7;
    border-radius: 2px;
    margin: 48px 0 80px;
    min-height: 450px;
    width: 800px;
    outline: none;
    padding: 20px;
}

.close-btn-ctn {
    display: flex;
}

.close-btn {
    height: 40px;
    width: 35px;
    font-size: 20px;
    color: #031D2C;
    border: none;
    border-radius: 25px;
}

.close-btn:hover {
    background-color: #DCDCDC;
}