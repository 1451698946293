.cc-crs-form-element {
  .cc-label-field-danger {
    @apply text-error font-bold;
  }
  .cc-chevron-circle {
    @apply ml-1 text-accent;
  }
  .cc-crs-panel-bar {
    @apply w-full my-6 pl-3 #{!important};
    .k-panelbar-header {
      @apply mb-2 #{!important};
    }
    .cc-collapse-header {
      @apply w-full cursor-pointer;
      .cc-collapse-container {
        @apply flex justify-between items-center;
        h6 {
          @apply mb-0 #{!important};
        }
        .k-checkbox-label {
          @apply font-normal text-body #{!important};
        }
      }
      h6 {
        @apply text-disabled #{!important};
      }
      hr {
        @apply w-full block border-disabled #{!important};
        border-width: 1px !important;
      }
    }
  }
  .k-panelbar-content {
    @apply py-3;
  }
  .cc-table-responsive {
    @apply overflow-x-auto;
    .cc-table {
      @apply w-full overflow-x-auto;
      .cc-thead-dark {
        @apply bg-secondary;
      }
      td {
        @apply p-2;
        input {
          min-width: 200px !important;
        }
      }
      th {
        @apply p-2 text-disabled font-bold;
      }
    }
    .cc-label {
      @apply m-0 #{!important};
      min-width: 200px !important;
    }
  }
}
