.cc-load-failed {
  @apply flex-grow h-full w-full;
}

.cc-reference-sidebar {
  //Custom for Right Slide Bar
  .cc-load-failed {
    @apply h-full #{!important};
  }
  .cc-result {
    h2 {
      @apply font-bold text-sm leading-0;
    }
    h6 {
      @apply text-sm;
    }
    .cc-result-icon {
      font-size: 2rem;
      line-height: 0.5;
    }
  }
}

// Custom in case cc-custom-panel-body in grid detail
.k-detail-row
  .k-detail-cell
  .cc-custom-panel-bar
  .cc-custom-panel-body
  > .cc-load-failed {
  @apply relative p-4;
}
