.cc-ref-body {
  @apply self-start w-full;
  @apply h-full overflow-y-auto p-4;
  @apply text-base #{!important};
  ul.k-panelbar {
    li.k-panelbar-item {
      @apply border-t-0;
      .k-link {
        &.k-selected {
          background-color: unset;
          .k-icon {
            @apply hidden;
          }
          &.k-focus {
            @apply shadow-none;
          }
        }
        .k-panelbar-content {
          @apply py-2;
        }
        .k-panelbar-item-text {
          @apply cursor-pointer w-full;
        }
        .k-icon {
          @apply hidden;
        }
        &:hover {
          background-color: unset;
        }
      }
    }
  }
}
.cc-ref-block {
  @apply text-base space-y-2;
}
.cc-ref-block-flex {
  @apply space-y-0 flex items-center flex-wrap py-2;
}
