.cc-contact-search-internal {
  min-width: 605px;
  .k-popup {
    @apply py-0 overflow-x-scroll;
    .k-list {
      min-width: 605px;
    }
  }

  .k-table-header {
    @apply p-0;
    .cc-search-header {
      @apply grid grid-cols-3 bg-secondary w-full;

      & > div {
        @apply p-2;
      }
    }
  }
  .cc-search-item {
    @apply grid grid-cols-3 w-full;
    & > div {
      @apply p-2 break-words;
    }
  }

  .k-list-content .k-list-ul .k-list-item {
    @apply p-0;
  }
}

.cc-contact-search-internal.cc-forward-internal {
  min-width: 400px;
  .k-table-header .cc-search-header {
    @apply p-0;
  }
}

.cc-contact-search-external {
  min-width: 702px;
  .k-popup {
    @apply py-0 overflow-x-scroll;
    .k-list {
      min-width: 700px;
    }
  }

  .k-table-header {
    @apply p-0;
    .cc-search-header {
      @apply grid grid-cols-4 bg-secondary w-full;

      & > div {
        @apply p-2;
      }
    }
  }
  .cc-search-item {
    @apply grid grid-cols-4 w-full;
    & > div {
      @apply p-2 break-words;
    }
  }

  .k-list-content .k-list-ul .k-list-item {
    @apply p-0;
  }
}

@media (max-width: 1600px) {
  .cc-contact-search-external,
  .cc-contact-search-internal {
    min-width: 605px;
  }
}

@media (max-width: 1000px) {
  .cc-contact-search-external,
  .cc-contact-search-internal {
    min-width: 400px;
    .k-popup {
      max-height: 400px;
    }
  }
}

@media (max-width: 350px) {
  .cc-contact-search-external,
  .cc-contact-search-internal,
  .cc-contact-search-internal.cc-forward-internal {
    min-width: 270px;
  }
}
