.cc-grid-search {
  min-height: 200px;
  @apply flex flex-col h-full;
  .cc-grid-search-input {
    @apply mb-4 flex-grow-0;
    .k-textbox {
      @apply w-full;
    }
  }
  .cc-grid-search-list {
    @apply flex flex-grow h-1 justify-center;
    .cc-grid {
      .k-grid {
        @apply h-full max-h-full #{!important};
        min-height: 200px;
      }
    }
  }

  .cc-expand-collapse-button-group {
    @apply flex flex-col items-end mb-2;
  }
}
