.insert-standard-solution {
  @apply pl-1;
}

.cc-cursor-pointer {
  @apply hover:cursor-pointer;
}

.cc-margin-top {
  @apply mt-4;
}

.cc-flex {
  display: flex;
}

.cc-justify-between {
  justify-content: space-between;
  .fa-plus {
    color: var(--success);
  }
}

.cc-align-item {
  align-items: center;
}
