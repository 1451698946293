.cc-state-roads-picker-search-popup {
  .k-popup {
    min-width: 250px;
    @apply py-0 overflow-x-hidden  #{!important};
    & > .k-list-scroller {
      @apply overflow-x-hidden;
      & > .k-list {
        & > .k-item {
          @apply p-0 #{!important};
        }
      }
    }
  }
  .cc-search-header {
    @apply flex bg-secondary w-full;

    & > div {
      @apply p-2;
    }
  }
  .cc-search-item {
    @apply flex w-full;
    & > div {
      @apply p-2 break-words;
    }
  }
  .k-list-scroller,
  .cc-search-header {
    min-width: 800px;
  }
}
.cc-state-roads-search {
  .cc-html-invalid {
    @apply border-error;
  }
  .cc-combobox-state-roads {
    @apply w-full;
  }
}

@media (max-width: 1000px) {
  .cc-state-roads-picker-search-popup {
    .k-popup {
      @apply py-0 overflow-x-auto #{!important};
      min-width: 250px;
    }
  }
}

.cc-state-roads-picker-search-dialog {
  .k-dialog-content {
    @apply h-full;
    .cc-dialog-body {
      @apply flex flex-col h-full;
      .cc-search-result {
        @apply flex flex-col h-full;
        .cc-search-result-title {
          @apply mb-4 flex-grow-0;
        }
        .cc-search-result-body {
          @apply flex flex-grow h-1 justify-center;
          .cc-grid {
            .k-grid {
              @apply h-full max-h-full #{!important};
              min-height: 200px;
            }
          }
        }
      }
    }
  }
}
