.cc-custom-export-fields-table {
  td {
    min-width: 200px;
    @apply text-left;
  }
  td:nth-child(1) {
    @apply min-w-0 text-right align-middle;
  }
  .cc-settings-description td {
    @apply border-none text-left p-0;
    word-wrap: break-word;
    white-space: break-spaces;
    min-width: 200px;
  }
}
