.cc-action-bar-multi-select {
  @apply w-full md:w-36 #{!important};
  @apply md:ml-1 md:mt-0 mt-1 ml-0 #{!important};

  .k-multiselect {
    .k-multiselect-wrap {
      @apply text-accent;
      height: 28px;
      .k-button {
        @apply bg-transparent text-accent;
        margin: 2px;
      }
    }
  }
}
