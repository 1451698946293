.cc-details-form-row {
  .cc-field {
    @apply pb-4;
  }
  .cc-margin-0 {
    @apply ml-0;
  }
}

.cc-clone-new-event-dialog.cc-dialog > div.k-window.k-dialog {
  max-height: 35%;
}

@media (max-width: 768px) {
  .cc-clone-new-event-dialog.cc-dialog > div.k-window.k-dialog {
    max-height: 50%;
  }
}

@media (max-width: 425px) {
  .cc-clone-new-event-dialog.cc-dialog > div.k-window.k-dialog {
    max-height: 70%;
  }
}
