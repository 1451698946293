.cc-list-view-filter {
  .cc-list-view-filter-header {
    @apply space-x-2 pb-2 pl-2 pt-1;
    .k-button.k-primary:not(.k-outline) {
      @apply text-white;
    }
    .k-text-primary {
      color: var(--currency-blue);
    }

    .k-primary {
      &:hover,
      &.k-hover {
        border-color: var(--cc-view-filter-header-primary-border-color-hover);
        background-color: var(--cc-view-filter-header-primary-bg-color-hover);
      }
    }

    .k-button-solid-base {
      &:hover,
      &.k-hover {
        &:not(.k-selected):not(.k-primary) {
          border-color: var(
            --cc-view-filter-button-solid-base-border-color-hover
          );
          background-color: var(
            --cc-view-filter-button-solid-base-bg-color-hover
          );
        }
      }
    }
  }
  .cc-list-view-filter-body {
    .k-filter,
    .k-filter-container,
    .k-filter-group-main,
    .k-toolbar {
      width: max-content;
    }
    .k-filter-toolbar {
      max-width: 405px;
    }
    .k-toolbar-item.k-filter-field > span {
      @apply w-auto;
      min-width: 10rem;
      max-width: 11.5rem;
    }
    .k-toolbar-item.k-filter-operator > span {
      @apply w-auto #{!important};
      min-width: 10rem;
      max-width: 11.5rem;
    }
    .k-toolbar {
      border-color: var(--cc-view-filter-toolbar-border-color);
      .k-filter-toolbar {
        @apply w-full;
        padding: 1px 0;
      }

      .k-button-solid-base {
        background-color: var(--cc-view-filter-button-solid-base-bg-color);
        &.k-selected {
          background-color: var(--cc-view-filter-selected-button-bg-color);
          border-color: var(--cc-view-filter-selected-button-border-color);
        }

        &:hover,
        &.k-hover {
          &:not(.k-selected) {
            border-color: var(
              --cc-view-filter-button-solid-base-border-color-hover
            );
            background-color: var(
              --cc-view-filter-button-solid-base-bg-color-hover
            );
          }
        }
      }

      .k-input-solid:focus-within {
        box-shadow: var(
          --cc-view-filter-input-box-shadow-focus-within
        ) !important;
      }

      .k-dropdownlist {
        background-color: transparent;
        border-color: var(--cc-view-filter-dropdownlist-border-color);
        &:hover {
          border-color: var(--cc-view-filter-dropdownlist-border-color-hover);
        }

        .k-button-solid-base {
          background-color: transparent;
          &:hover {
            background-color: transparent;
            border-color: transparent;
          }
        }
      }
    }
  }
}
