.cc-site-address-search {
  .k-popup {
    @apply rounded-none;
    .k-list {
      @apply overflow-auto bg-secondary;
      .k-table-header {
        width: max-content;
        @apply bg-secondary;
        .cc-search-header {
          @apply flex items-center;
          min-height: 36px;
          div {
            @apply pl-2;
          }
        }
      }
      .k-list-content {
        width: max-content;
        @apply bg-component-bg;
        ul {
          list-style-type: none;
          margin: 0;
          li {
            @apply p-0;
            .cc-search-item {
              @apply flex w-full;
              & > div {
                @apply p-2 break-words;
              }
            }
          }
        }
      }
    }
  }
}

.cc-site-address-search.cc-search-view-full {
  .k-popup {
    width: fit-content; // set full width popup
    max-width: 800px;
  }
}

@media (max-width: 1000px) {
  .cc-site-address-search {
    .k-popup {
      @apply py-0 overflow-x-auto #{!important};
      min-width: 300px;
    }
  }
}
