.cc-panel {
  @apply flex flex-col;
  &.cc-panel-disabled {
    @apply opacity-80;
  }
  &.cc-panel-invalid {
    & > .cc-panel-header {
      @apply border-error #{!important};

      .cc-panel-header-title,
      .cc-panel-header-badge,
      .cc-panel-header-sub-title {
        @apply text-error #{!important};
      }
    }
  }
  &.cc-panel-collapsed {
    & > .cc-panel-body {
      min-height: 0px;
      @apply h-0 pt-0;
    }
  }

  .cc-panel-header {
    @apply cursor-pointer py-1 hover:opacity-70 w-full;
    @apply border-b border-disabled;
    @apply flex justify-between items-center space-x-2;
    @apply transition ease-in-out;

    .cc-panel-header-left {
      @apply flex items-center space-x-2 w-3/4;

      .cc-panel-header-title {
        @apply font-medium text-sm text-disabled truncate;
      }
      .cc-panel-header-badge {
        @apply font-bold text-xs text-disabled truncate;
        @apply border border-border px-2 rounded-3xl;
        padding-bottom: 1px;
      }
    }
    .cc-panel-header-right {
      @apply flex justify-end w-1/4;
      .cc-panel-header-sub-title {
        @apply font-normal text-sm text-disabled truncate;
      }
    }
  }
  .cc-panel-body {
    @apply overflow-hidden  transition-all;
    @apply pt-4;
    min-height: 16px;
  }
}
