.active-menu-action {
  font-weight: bold !important;
}

.hight-light-color {
  color: var(--warning) !important;
}

.more-option {
  color: var(--component-color);
  padding: 0.5rem 1rem;
}
.btn-close button i {
  transform: translateY(-50%);
}

// custom panelbar
.k-panelbar {
  .k-item {
    border: none !important;
  }
  border: none !important;
  background-color: transparent !important;
  .k-item.k-state-default {
    span.k-link.k-header {
      padding: 0px;
      border-bottom: var(--component-color);

      .k-icon.k-i-arrow-s,
      .k-i-arrow-n {
        visibility: hidden;
      }
    }
    span.k-link {
      border: none !important;
      background-color: transparent !important;
    }

    .k-state-selected {
      border: none !important;
      // background-color: transparent !important;
    }

    .k-state-focused {
      box-shadow: none !important;
    }
  }
}

.k-content {
  background-color: transparent !important;
}

hr {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.k-animation-container-relative {
  display: block !important;
}

.list-group-item {
  padding: 0.4rem 1.25rem !important;
  background-color: inherit !important;
}

.card-header {
  padding: 0.5rem 1.25rem !important;
}

.k-tabstrip-items {
  overflow-y: hidden;
}
