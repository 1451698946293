.cc-officer-picker-search {
  .k-popup {
    min-width: 250px;
    @apply py-0 overflow-x-hidden  #{!important};
    & > .k-list-scroller {
      @apply overflow-x-hidden;
      & > .k-list {
        & > .k-item {
          @apply p-0 #{!important};
        }
      }
    }
  }
  .k-list {
    .k-list-item {
      @apply p-0 #{!important};
    }
  }
  .cc-search-header {
    @apply flex bg-secondary w-full;

    & > div {
      @apply p-2;
    }
  }
  .cc-search-item {
    @apply flex w-full;
    line-height: 1em;
    & > div {
      @apply p-2 break-words;
    }
  }
  .k-list-scroller,
  .cc-search-header {
    min-width: 800px;
  }
}
@media (max-width: 1000px) {
  .cc-officer-picker-search {
    .k-popup {
      @apply py-0 overflow-x-auto #{!important};
      min-width: 250px;
    }
  }
}

.cc-officer-picker-search {
  .k-dialog-content {
    @apply h-full;
    .cc-dialog-body {
      @apply flex flex-col h-full;
      .cc-search-result {
        @apply flex flex-col h-full;
        .cc-search-result-title {
          @apply mb-4 flex-grow-0;
        }
        .cc-search-result-body {
          @apply flex flex-grow h-1 justify-center;
          .cc-grid {
            .k-grid {
              @apply h-full max-h-full #{!important};
              min-height: 200px;
            }
          }
        }
      }
    }
  }
}

.cc-input-picker-new {
  .k-multiselect {
    height: auto;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    .k-chip-list {
      .k-chip {
        height: 22px;
        margin-top: 0rem;
      }
    }

    input.k-input-inner {
      padding: 3px;
    }
  }

  .cc-input-picker-button {
    align-self: baseline;
  }
}

.cc-search-item-skeleton {
  @apply flex h-5 w-full animate-pulse bg-secondary my-1 mx-2 rounded-md;
}

.cc-multiple-search-item-skeleton {
  @apply flex h-5 w-full animate-pulse bg-secondary rounded-md;
}
