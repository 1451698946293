.cc-loading {
  @apply w-full h-full flex justify-center items-center p-4 fixed top-0 left-0 backdrop-filter backdrop-blur-sm;
  @apply z-loading #{!important};
  background-color: rgba(0, 0, 0, 0.2);
  .cc-loading-container {
    @apply w-10 h-10 bg-transparent;
    .cc-loader {
      @apply animate-spin text-accent;
      .cc-loader-circle {
        @apply opacity-40;
      }
      .cc-loader-path {
        @apply opacity-95;
      }
    }
  }
}

.cc-overwrite-loading .cc-loading {
  @apply relative bg-transparent;
  @apply z-auto #{!important};
}
