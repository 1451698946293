.cc-caculate-grid-dialog {
  .k-dialog-content {
    .cc-dialog-body {
      @apply flex flex-col h-full;
    }
  }

  .cc-grid-dialog-content {
    min-height: 200px;
    @apply flex flex-col h-full;
    .cc-grid-input {
      @apply mb-4 flex-grow-0;
      .k-textbox {
        @apply w-full;
      }
    }
    .cc-grid-list {
      @apply flex flex-grow h-full justify-center;
      .cc-grid {
        .k-grid {
          @apply h-full max-h-full #{!important};
          min-height: 250px;
        }
      }
    }
  }
}
