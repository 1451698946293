.cc-change-of-ownership-names-step {
  .cc-edit-field-button {
    width: 14px !important;
    height: 14px !important;
    @apply ml-2 hover:text-warning #{!important};
  }
  .cc-name-tooltip-group {
    @apply flex;
  }
}
