.cc-search-page {
  @apply flex flex-col h-full;
  .cc-search-form {
    @apply flex-grow-0 pt-4;
    .cc-search-filter {
      @apply flex items-start;
      .cc-search-filter-input {
        @apply flex flex-col flex-grow w-1;
      }
      .k-button {
        @apply ml-1;
      }
    }
  }
  .cc-search-result {
    @apply flex flex-grow flex-col h-1;
    min-height: 300px;
    .cc-search-result-title {
      @apply mt-4 flex-grow-0;
    }
    .cc-search-result-body {
      @apply flex flex-grow h-1 justify-center;
      .cc-grid {
        @apply h-full pb-2;
      }
    }
  }
}
