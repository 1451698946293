.cc-tooltip-validator {
  .k-tooltip {
    @apply border-l-4 border-error rounded-sm shadow-sm text-sm p-2 #{!important};
  }
  .cc-validator-result {
    @apply block  text-error font-semibold;
  }
}

.cc-tooltip-information {
  .k-tooltip {
    @apply rounded-sm shadow-sm text-sm p-2 m-2 #{!important};
  }
}

.cc-tooltip-icon {
  @apply align-top ml-1 h-full transform -translate-y-1 #{!important};
  font-size: 0.4rem !important ;
}

.cc-tooltip-validator-icon {
  @apply align-top text-error h-full ml-1 mt-1 transform -translate-y-1 #{!important};
  font-size: 0.4rem !important ;
}

.cc-tooltip-html-content {
  * {
    line-height: normal !important;
  }

  ol,
  ul {
    list-style: revert !important;
    padding: revert !important;
    margin: unset !important;
  }
}

.cc-tooltip-enabled-scroll {
  @apply max-h-60 overflow-auto;
}
