.cc-animal-form-element {
  .cc-label-field-free-os {
    @apply text-error font-bold;
  }
  .cc-animal-owner-button {
    @apply p-0 hover:text-success #{!important};
    width: 14px !important;
    height: 14px !important;
  }
}

.animal-form-flex-col {
  @apply flex flex-col;

  .cc-field {
    @apply mb-4;
  }
}
