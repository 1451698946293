.cc-document-form {
  .cc-add-image-button {
    @apply flex w-full justify-center items-center;
    .fa-plus {
      @apply text-success;
    }
  }
  .cc-remove-image-button {
    @apply flex w-full justify-end items-center;
    .fa-minus {
      @apply text-error;
    }
  }
  .cc-custom-download-document {
    @apply flex flex-row gap-5;
  }
  .cc-image-title {
    @apply items-center;
  }
  .cc-file-error {
    border-color: var(--error);
  }
  .cc-divider-document {
    @apply w-full border-gray-600 border-b m-0 block my-4 #{!important};
  }
  .cc-document-card-container {
    @media (max-width: 950px) {
      @apply gap-4;
    }
    @media (max-width: 700px) {
      @apply grid-cols-1 grid gap-3;
    }
    @apply grid grid-cols-2 gap-8;
  }
}
