.cc-grid-header-cell {
  @apply flex space-x-1 cursor-pointer items-center;
  .cc-grid-header-cell-title {
    min-height: 14px;
  }
  .k-i-filter {
    @apply text-accent;
    font-size: 0.78rem;
    &::before {
      margin-bottom: -1px;
    }
  }
  .k-i-sort-desc-sm,
  .k-i-sort-asc-sm {
    &::before {
      margin-bottom: -3px;
    }
  }
}

.cc-grid-header-cell.padding-right {
  @apply pr-4;
}
