.cc-year-control {
  @apply flex justify-center items-center gap-x-12;
  .cc-arrow-icon {
    @apply text-accent cursor-pointer;
    font-size: 1.5rem;
  }
  p {
    @apply text-4xl mb-2;
  }
}
