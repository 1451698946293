.cc-smartphone {
  height: 540px;
  @apply bg-secondary rounded-3xl px-4 pt-6 pb-10 w-72 relative;
  &:before {
    left: 46%;
    @apply bg-disabled absolute top-3  w-8 h-1 rounded-full;
    content: " ";
  }
  &:after {
    @apply bg-disabled absolute bottom-2 rounded-full w-6 h-6;
    content: " ";
    left: 46%;
  }
  .cc-smartphone-screen {
    @apply bg-body-bg w-full h-full p-2 overflow-y-auto leading-tight;
    /* Scrollbar Style */
    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      @apply bg-body-bg;
    }

    &::-webkit-scrollbar-thumb {
      @apply bg-accent;
    }
    .cc-sms-content {
      @apply bg-accent text-white p-2 rounded-t-lg rounded-br-2xl;
    }
    // .cc-email-content {
    //   ul {
    //     @apply list-disc pl-6;
    //   }
    //   ol {
    //     @apply list-decimal pl-6;
    //   }
    // }
  }
}
