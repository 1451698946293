#manage-wastewater {
  .custom-input-group {
    display: flex;
    
    .k-numeric-wrap {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .input-group-text {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}
