.cc-animal-form-element {
  @apply py-2;
  .attachtment {
    height: 150px;
    width: 450px;
  }
  .fa-times {
    @apply text-error;
  }
  .fa-check {
    @apply text-success;
  }
  .fa-spinner {
    @apply text-accent;
  }
  .cc-image-upload {
    @apply w-28 h-28  rounded relative border border-border;
    .cc-image-upload-control {
      @apply w-full h-full absolute  top-0 left-0 bg-gray-50 bg-opacity-0 opacity-0 flex items-center justify-center hover:bg-opacity-60 hover:opacity-100 transition duration-200 ease-in-out;
      .fa-upload {
        @apply text-accent cursor-pointer;
      }
      .fa-times {
        @apply text-error cursor-pointer;
      }
    }
    .cc-image-review {
      @apply w-full h-full object-cover rounded;
    }
  }
}
