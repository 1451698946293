.cc-form .cc-input-picker-new.cc-case-use-textarea {
  .cc-input-picker-button {
    height: 30px !important;
  }
}

.cc-input-picker-new {
  @apply flex items-end;
  .cc-input-picker-field {
    @apply flex-grow rounded-r-none;
  }
  .cc-input-picker-html {
    @apply flex-grow rounded-r-none rounded-l py-1 px-2 truncate;
    height: 28px;
    border: 1px solid #ced4da;
  }
  .cc-wrap-input {
    @apply flex-auto;
    &:hover {
      .cc-clear-button {
        @apply visible;
      }
    }
  }
  .cc-input-picker-button {
    @apply flex-grow-0 p-0 rounded-l-none border-border border-l-0 hover:border-border;
    @apply text-disabled hover:text-accent bg-transparent hover:bg-transparent;
    height: 28px !important;
    width: 39px !important;

    &.cc-clear-button {
      @apply rounded-none border-l-0 text-error invisible outline-none border-0 shadow-none;
      width: 28px !important;
      margin-left: -28px;
      .k-icon {
        @apply text-base;
      }
    }
  }

  &.cc-input-picker-invalid {
    .cc-input-picker-button {
      @apply border-error;
    }
  }

  .k-combobox {
    @apply rounded-tr-none rounded-br-none;
    button {
      background-color: transparent;
      color: var(--disabled);
      &:hover {
        color: var(--accent);
      }
    }
    .k-dropdown-wrap,
    .k-select {
      @apply rounded-tr-none rounded-br-none #{!important};
    }
    .k-select {
      @apply bg-transparent text-disabled hover:text-accent #{!important};
    }
    .k-state-focused {
      @apply shadow-none #{!important};
    }
    .k-clear-value {
      @apply text-error;
    }
  }
}
