.kb-grid-width {
  .k-grid-header col:nth-of-type(1) {
    width: 80%;
  }
  .k-grid-table col:nth-of-type(1) {
    width: 80%;
  }
  .k-grid-header col:nth-of-type(2) {
    width: 20%;
  }
  .k-grid-table col:nth-of-type(2) {
    width: 20%;
  }
}
.div-margin-bottom-10 {
  margin-bottom: 10px;
}
.btn-width-100 {
  width: 100%;
}
