/* import tailwindcss */
@tailwind base;
@tailwind components;
@tailwind utilities;
/*----------------------------------------*/
@layer components {
  //Hidden class name
  .cc-hidden {
    @apply hidden #{!important};
  }
  //Grid Tool Bar
  .cc-grid-tools-bar {
    @apply flex w-full justify-end space-x-1;
    .fa-arrow-alt-down,
    .fa-arrow-alt-up,
    .fa-clone,
    .fa-pencil-alt,
    .fa-pencil,
    .fa-save {
      @apply text-accent;
    }
    .fa-plus {
      @apply text-success;
    }
    .fa-minus {
      @apply text-error;
    }
    .k-toolbar,
    .k-widget {
      align-self: unset !important;
    }
  }

  //text-overflow-ellipsis
  .text-one-line {
    @apply text-left overflow-hidden overflow-ellipsis whitespace-nowrap;
  }
  // nav button

  /*  @TODO: remove */
  .cc-nav-button-temp {
    @apply hover:text-warning  text-accent #{!important};
    @apply p-0 bg-transparent focus:shadow-none border-none #{!important};

    &:disabled {
      @apply text-disabled #{!important};
    }
  }

  .cc-link-transparent {
    @apply hover:no-underline #{!important};
  }

  //icon button
  .cc-icon-button {
    @apply text-accent hover:text-warning #{!important};
  }

  .cc-icon-button-active {
    @apply text-warning #{!important};
  }
  //Sub Panel Bar
  .cc-custom-sub-panel-bar {
    @apply pl-3 #{!important};
  }
  //Label control
  .cc-label-control-between {
    @apply flex justify-between items-center space-x-2;
  }

  //Grid control
  .cc-grid-control-between {
    @apply flex justify-between flex-wrap items-center;
    .cc-grid-control-left {
      @apply mr-2;
    }
  }
  .cc-grid-control-left,
  .cc-grid-control-right {
    * {
      @apply m-0;
    }

    .cc-control-item {
      @apply flex items-center space-x-1 md:w-auto w-full md:mb-0 mb-2;
      .k-checkbox-label {
        padding-bottom: 1px;
      }
    }
  }
  .cc-grid-control-left {
    @apply flex justify-start flex-wrap items-center mb-2 md:w-auto w-full;
    .cc-control-item {
      @apply md:mr-2;
    }
  }
  .cc-grid-control-right {
    @apply flex lg:justify-end flex-wrap items-center mb-2 lg:w-auto w-full justify-start;
    .cc-control-item {
      @apply md:ml-2;
    }
  }
  .cc-icon-loading-free {
    @apply ml-2;
  }

  //product action bar
  .cc-product-action-bar {
    .cc-action-bar-row {
      @apply border-t border-b border-border flex items-center py-1 flex-wrap;
      .cc-save-bookmark-button {
        @apply flex-grow text-right pr-1 space-x-1;
      }
      .cc-navigation-more {
        @apply flex  items-center border-l-0 md:border-l border-border;
        height: 28px;
      }
    }
    .cc-label-button {
      @apply block px-2 py-1 m-0 text-accent hover:text-warning cursor-pointer;
    }
    .cc-label-button-active {
      @apply text-warning font-bold;
    }
    .cc-label-button-disabled {
      @apply text-disabled cursor-default hover:text-disabled pointer-events-none;
    }
    .cc-label-button-workflow {
      @apply text-warning;
    }
  }
  //dialog footer
  .cc-dialog-footer-actions-right,
  .cc-dialog-footer-actions-left {
    @apply flex sm:flex-row flex-col sm:items-center flex-wrap;
    .cc-dialog-button {
      @apply font-bold text-sm py-1 px-4 md:px-6 mx-2 my-1;
    }
  }
  .cc-dialog-footer-actions-right {
    @apply justify-end;
  }
  .cc-dialog-footer-actions-left {
    @apply justify-start;
  }

  .cc-label-field {
    @apply block py-1 leading-5 break-words;
  }
  .cc-form-control {
    @apply w-full #{!important};
  }

  .cc-label-empty {
    @apply hidden md:block;
  }
  .cc-label-empty:before {
    content: "\200B";
  }
  .cc-icon-label-button {
    width: 14px !important;
    height: 14px !important;
    @apply ml-2;
  }
  .cc-label {
    @apply text-disabled flex space-x-2;
  }
  .cc-label-right-aligned {
    @apply text-disabled flex space-x-2 justify-end;
  }

  .cc-divider {
    @apply w-full border-border border-b m-0 block my-4 #{!important};
  }
  .cc-highlight-alert {
    *:not(.cc-label) {
      @apply text-error #{!important};
    }
  }

  .cc-full-width {
    @apply w-full;
  }
  .cc-manage-notification {
    @apply overflow-y-auto pr-2 mb-1 flex-col space-y-2;
    max-height: 150px;
  }
  .cc-manage-container {
    @apply flex lg:flex-row pt-2 flex-col-reverse flex-grow overflow-hidden;
    .cc-manage-left {
      @apply py-0 lg:py-1 w-full flex-grow overflow-hidden flex flex-col;

      .cc-manage-form-body {
        @apply flex-grow overflow-y-auto pr-2 h-4/6;
      }
    }
    .cc-manage-right {
      @apply w-full lg:w-1/2 xl:w-1/3 lg:pl-6 pl-0 lg:pt-0 pt-2 min-h-full overflow-auto;
      .cc-reference-sidebar {
        @apply h-full;
        .k-tabstrip {
          @apply h-full;
        }
      }
    }
  }

  .cc-form {
    @apply py-3;
    .cc-field-group {
      & > .cc-label {
        @apply m-0 #{!important};
      }
      @apply space-y-4;
      & > :empty {
        @apply m-0 #{!important};
      }
      .cc-form-cols-1 {
        @apply grid grid-cols-1 md:grid-cols-1 gap-x-4 gap-y-4;
      }
      .cc-form-cols-2 {
        @apply grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-4;
      }
      .cc-form-cols-3 {
        @apply grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-x-4 gap-y-4;
      }
      .cc-form-cols-4 {
        @apply grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-4 gap-y-4;
        .k-radio-item {
          @apply block py-1;
        }
        .cc-col-span-3 {
          @apply xl:col-span-3 lg:col-span-2 flex flex-row items-end;
        }
      }
      .cc-form-cols-5 {
        @apply grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-x-4 gap-y-4;
        .k-radio-item {
          @apply block py-1;
        }
        .cc-col-span-3 {
          @apply xl:col-span-3 lg:col-span-2 flex flex-row items-end;
        }
      }
      .cc-field {
        @apply flex flex-col col-span-1 md:col-span-1;
        .k-dropdown {
          @apply border-none #{!important};
        }
        .k-textbox {
          @apply shadow-none;
        }
      }
      .cc-col-span-2 {
        @apply md:col-span-2;
      }
      .cc-col-span-3 {
        @apply md:col-span-3;
      }
      .cc-input-group-cols-2 {
        @apply grid grid-cols-2 gap-x-2;
      }
      .cc-input-group-cols-3 {
        @apply grid grid-cols-3 gap-x-2;
      }

      .cc-custom-input-group {
        .k-input-solid:hover {
          border-color: rgba(0, 0, 0, 0.16);
        }
        @apply flex flex-row;
        .cc-input-group-postfix {
          @apply truncate px-4 flex items-center justify-center bg-secondary border border-border rounded-tr rounded-br max-h-7;
          margin-left: -1px;
        }
        & > span > span {
          @apply rounded-tr-none rounded-br-none border-r-0 #{!important};
        }
        .k-numerictextbox {
          .k-select {
            @apply rounded-none;
          }
        }
        .k-textbox {
          @apply rounded-tr-none rounded-br-none;
        }

        p {
          @apply mb-0 #{!important};
        }
      }

      @media (min-width: 450px) {
        .cc-custom-grid-cols-1-2-2 {
          grid-template-columns: 1fr 2fr 2fr !important;
        }
      }

      .cc-tool-tip-wrapper {
        @apply flex justify-between;
      }
      .cc-label-field-danger {
        @apply text-error font-bold;
      }
      .cc-label-field-success {
        @apply text-success font-bold;
      }
    }
    .cc-form-disabled {
      @apply pointer-events-none #{!important};
    }
  }
}

//Fix The back icon is skewed when the browser zoom in
// .k-button-icon,
// .cc-button-icon-rounded {
//   & > * {
//     line-height: 0px !important;
//   }
// }
//Textarea style
.k-textarea.k-disabled {
  @apply bg-secondary;
}

// dialog bur
.k-overlay {
  @apply backdrop-filter backdrop-blur-sm opacity-100 #{!important};
  background-color: rgba(0, 0, 0, 0.2) !important;
}

//Display scroll for grid in detail
.k-detail-row .k-detail-cell {
  .k-grid-content.k-virtual-content {
    @apply overflow-auto #{!important};
  }
  .k-grid-content.k-virtual-content > div:first-child {
    overflow: unset;
  }
}

//switch style
.k-switch {
  height: 26px !important;
  margin-bottom: 2px;
  .k-switch-handle {
    width: 20px !important;
    height: 20px !important;
  }
}

.k-switch-on .k-switch-handle {
  left: calc(100% - 22px) !important;
}

//dropdown style
.k-dropdown {
  @apply bg-transparent  #{!important};
  .k-input,
  .k-icon {
    @apply text-sm leading-4 #{!important};
  }
  .k-dropdown-wrap {
    @apply bg-transparent #{!important};
  }
}
// k-grid detail style
.k-master-row.k-state-selected {
  + .k-detail-row {
    background-color: var(--k-detail-color) !important;
  }
  + .k-detail-row.k-alt {
    background-color: var(--k-detail-alt-color) !important;
  }
}

.k-hierarchy-cell > .k-icon {
  @apply p-0 #{!important};
}

.k-hierarchy-cell > .k-icon.k-plus {
  @apply w-4 #{!important};
}

.k-detail-row {
  & > .k-hierarchy-cell {
    @apply border-border border-t border-b;
    border-bottom-width: 1px !important;
    border-top-width: 1px !important;
  }
  .k-detail-cell {
    @apply border-border border-t border-b py-4 pr-3 #{!important};
    padding-left: 2px !important;
  }
}

//end
// z-index config
.Toastify__toast-container {
  @apply z-toast #{!important};
}
.k-panelbar {
  .k-animation-container {
    @apply z-auto #{!important};
  }
}

//# Move form themes
//### Root
html,
body {
  @apply w-full h-full;
}
#root {
  @apply w-full h-full bg-body-bg;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply text-component;
  }
}
textarea {
  line-height: normal !important;
}
//### @Todo remove when clear
.border-radius-0 {
  @apply rounded-none #{!important};
}
.cc-activity-group {
  .cc-activity-group-title {
    @apply text-warning;
  }
}
.label {
  .btn-link {
    @apply cursor-pointer text-accent;
  }
}

.link-button {
  @apply bg-transparent border-none cursor-pointer inline m-0 p-0;
}

.link-button:hover,
.link-button:focus {
  text-decoration-color: var(--accent);
  outline: none;
}
.cc-activity-item {
  @apply text-white bg-accent;
}

.cc-group-name {
  @apply text-warning;
}
// ### kendo color

.k-button.k-primary:not(.k-outline) {
  @apply bg-accent;
}

.k-grid a {
  @apply hover:text-accent;
}

th {
  .k-header {
    @apply text-sm;
  }
}
.k-grid {
  td {
    @apply text-one-line;
    div,
    a {
      @apply text-one-line;
    }
  }
}
.k-pager-wrap {
  @apply overflow-auto #{!important};
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  .k-pager-sizes .k-dropdown,
  .k-pager-sizes .k-dropdowntree,
  .k-pager-sizes > select {
    min-width: 70px;
  }
}

.k-window {
  @apply bg-body-bg;
}

// Old setting --------------------------------------------------------------------------------------------------
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Scrollbar Style */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  @apply bg-secondary;
}

::-webkit-scrollbar-thumb {
  @apply bg-border rounded-full transition duration-500 ease-in-out;
}

::-webkit-scrollbar-thumb:hover {
  @apply bg-disabled rounded-full;
}
/* Input autofill style */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--body-bg) inset !important;
  -webkit-text-fill-color: var(--body-color);
}
/*----- 1 Font Settings -----*/
/*----------------------------------------*/
/* text sizes */
.text-size-xxl {
  font-size: xx-large;
}
.text-size-xl {
  font-size: x-large;
}
.text-size-lg {
  font-size: larger;
}
.text-size-md {
  font-size: medium;
}
.text-size-sm {
  font-size: small;
}
.text-size-xs {
  font-size: x-small;
}
.text-size-xxs {
  font-size: xx-small;
}
/* line height */
.line-height-null {
  line-height: 1em;
}
/* font weights */
.font-weight-200 {
  font-weight: 200;
}
.font-weight-300 {
  font-weight: 300;
}
.font-weight-400 {
  font-weight: 400;
}
.font-weight-500 {
  font-weight: 500;
}
.font-weight-600 {
  font-weight: 600;
}
.font-weight-700 {
  font-weight: 700;
}
/* vertical aligns */
.vert-bottom {
  vertical-align: bottom;
}
.vert-middle {
  vertical-align: middle;
}
.vert-sub {
  vertical-align: sub;
}

/* text color */
.text-queue {
  color: var(--queue);
}

.text-blue {
  color: var(--blue);
}

.text-indigo {
  color: var(--indigo);
}

.text-purple {
  color: var(--purple);
}

.text-purple {
  color: var(--purple);
}

.text-pink {
  color: var(--pink);
}

.text-red {
  color: var(--red);
}

.text-orange {
  color: var(--orange);
}

.text-yellow {
  color: var(--yellow);
}

.text-green {
  color: var(--green);
}

.text-teal {
  color: var(--teal);
}

.text-cyan {
  color: var(--cyan);
}

.text-white {
  color: var(--white);
}

.text-gray {
  color: var(--gray);
}

.text-gray-dark {
  color: var(--gray-dark);
}

.text-primary {
  color: var(--primary);
}

.text-secondary {
  color: var(--secondary);
}

.text-success {
  color: var(--success);
}

.text-info {
  color: var(--info);
}

.text-warning {
  color: var(--warning);
}

.text-danger {
  color: var(--danger);
}

.text-light {
  color: var(--light);
}

.text-dark {
  color: var(--dark);
}

.text-accent {
  color: var(--accent);
}

.text-component-color {
  color: var(--component-color);
}

.fal-circle {
  border-radius: 50%;
  border: 1px solid white;
  padding: 5px 5px;
  width: 24px;
  height: 24px;
}

.k-window-title.k-dialog-title {
  line-height: 1.35;
  font-weight: 500;
  font-size: 1.09375rem;
}
.k-dialog {
  box-shadow: rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.k-overlay {
  background-color: rgba(17, 17, 17, 0.5) !important;
  opacity: 1 !important;
  backdrop-filter: blur(5px) !important;
  -webkit-backdrop-filter: blur(5px) !important;
}

.ccBackButton {
  font-size: 2em;
  color: #d9f0f2;
}

.ccBackButton:hover {
  color: #b2e9ed !important;
  cursor: pointer;
}

.circle-back-button {
  border-radius: 50% !important;
  width: 35px;
  height: 35px;
  background: #d9f0f2 !important;
}

.circle-back-button .back-icon {
  color: var(--black);
  font-size: "1.2rem";
}

.label {
  color: var(--disabled);
}

.label-value {
  display: block;
  margin-top: 4px;
  line-height: 1.5;
  white-space: pre-line;
  color: var(--component-color);
}

.k-state-default:not(.k-state-focused) {
  background-color: unset !important;
}

/* Dropdown List */
.k-list .k-item,
.k-list-optionlabel {
  padding: 4px 1.5rem !important;
}
.k-item.k-state-focused {
  box-shadow: none !important;
}
.k-list-scroller {
  overflow-y: auto !important;
  overflow-x: hidden !important;
}
.k-list .k-item,
.k-list-optionlabel {
  word-break: break-word;
}
.k-tooltip {
  padding: 0.5rem !important;
  font-size: medium !important;
  border-radius: 0px !important;
  border-color: var(--body-bg) !important;
  color: var(--disabled) !important;
  font-size: 15px !important;
  height: infinite !important;
  background-color: var(--secondary) !important;
}

.form-group .k-widget.k-dropdown {
  border-width: 0px;
}

.label-disabled {
  cursor: "pointer";
  color: var(--disabled);
}

.k-form .k-dropdown,
.k-form .k-dropdown-wrap,
.k-form .k-dropdowntree,
.k-form .k-dropdown-wrap .k-datepicker,
.k-form .k-picker-wrap,
.k-form .k-timepicker .k-picker-wrap,
.k-form .k-datetimepicker .k-picker-wrap {
  height: calc(0.75rem + 1em + 2px) !important;
}

.k-grid.grid-product-list .k-grid-container .k-master-row td {
  line-height: normal;
}
.nav-item {
  font-size: unset !important;
}
.small-size-tooltip {
  font-size: 0.34rem;
}

// Custom panelbar
.k-panelbar > .k-panelbar-item > .k-link {
  :focus {
    box-shadow: none !important;
  }

  .k-panelbar-item-text {
    width: 100%;
  }

  //hidden upp and down icon in panel
  .k-svg-icon.k-svg-i-chevron-up.k-panelbar-toggle.k-panelbar-collapse,
  .k-svg-icon.k-svg-i-chevron-down.k-panelbar-toggle.k-panelbar-expand {
    display: none;
  }
  background-color: transparent !important;
}

.k-panelbar > .k-item > .k-link,
.k-panelbar > .k-panelbar-header > .k-link {
  padding: 0px !important;
}

.k-panelbar > .k-item > .k-link:focus,
.k-panelbar > .k-item > .k-link.k-state-focus,
.k-panelbar > .k-item > .k-link.k-state-focused,
.k-panelbar > .k-panelbar-header > .k-link:focus,
.k-panelbar > .k-panelbar-header > .k-link.k-state-focus,
.k-panelbar > .k-panelbar-header > .k-link.k-state-focused {
  box-shadow: none !important;
}

.cc-detail-tab-link {
  @apply no-underline #{!important};
}

.cc-settings-description {
  @apply text-error leading-tight;
}

.cc-nav-type-single {
  .cc-nav-icon-button {
    .k-button-text {
      @apply hidden;
    }
  }
}

.cc-label {
  @apply mb-1;
}

// Grid - listview -  pager
.k-pager-nav {
  &.k-pager-first {
    border-radius: 0.25rem 0 0 0.25rem;
    border-right-width: 0 !important;
  }
  &.k-pager-last {
    border-radius: 0 0.25rem 0.25rem 0;
    border-left-width: 0 !important;
  }
}
