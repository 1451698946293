.systemsetting-panel {
  background-color: var(--card-cap-bg);
  color: var(--accent);
}

.systemsetting-panel .col-border {
  border-bottom: 0.15rem solid var(--accent) !important;
}

.systemsetting-panel a {
  text-decoration: none !important;
}

.systemsetting-panel a:hover {
  cursor: pointer;
}
