.cc-action-bar-nav-dropdowns {
  @apply flex items-center flex-wrap;
  @apply md:flex-row flex-col;
  @apply md:space-x-1 space-x-0 md:space-y-0 space-y-1;
  @apply md:w-auto w-full;
}

.cc-action-bar-multi-select {
  @apply w-36 #{!important};
  @apply md:ml-1 md:mt-0 mt-1 ml-0 #{!important};

  .k-multiselect {
    .k-multiselect-wrap {
      @apply text-accent;
      height: 28px;
      .k-button {
        @apply bg-transparent text-accent;
        margin: 2px;
      }
    }
  }
}

.cc-navbar-dropdown,
.cc-navbar-dropdown-tree {
  @apply bg-transparent md:w-auto w-full #{!important};

  min-width: 160px !important;
  max-width: none !important;
  .k-input,
  .k-icon {
    @apply text-accent text-sm #{!important};
  }
  .k-input {
    @apply truncate block leading-4 #{!important};
  }
  .k-dropdown-wrap {
    @apply bg-transparent #{!important};
  }
}
.cc-navbar-dropdown-tree {
  .k-clear-value {
    @apply hidden;
  }
}
@media (min-width: 768px) {
  .cc-action-bar-nav-dropdowns {
    .cc-navbar-dropdown,
    .cc-navbar-dropdown-tree {
      max-width: 300px !important;
    }
  }
}
.cc-navbar-dropdown-single {
  @apply md:ml-1 md:mt-0 ml-0 mt-1;
  .k-input-value-text {
    color: var(--accent);
  }
}
