.sidebar-container-autotabpage {
  overflow: auto;
  height: 100%;
  .k-tabstrip > .k-content {
    border-style: none;
  }

  .k-animation-container.k-animation-container-relative {
    width: 100%;
  }
}
