#manage-crmssystemsetting-tab-general {
  .question {
    display: flex;
    align-items: center;
    border: 1px solid lightgray;
    border-radius: 9999px;
    padding: 0.8rem;
    width: 22rem;
    a {
      margin-left: 0.2rem;
    }
  }

  .title {
    color: lightgray;
    border-bottom: 1px solid lightgrey;
    padding-bottom: 0.5rem;
    padding-left: 0rem;
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  .edit-field {
    margin-left: 0.5rem;
  }
  .fade:not(.show) {
    opacity: 1;
  }
}
