.cc-exist-global-setting-manage-form {
  @apply h-full;
  .k-form {
    @apply flex flex-col h-full;
    .cc-form-button-container {
      @apply flex-grow-0;
    }
    .cc-manage-content-settings {
      @apply flex flex-grow flex-col overflow-auto pr-2 pt-1;
      padding-left: 3px;
      .cc-global-component-setting {
        @apply mt-1;
        section.cc-field-group {
          @apply mb-3;
        }
      }
      .cc-form-cols-finance {
        @apply xl:w-1/2;
      }
    }
  }
}
