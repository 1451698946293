.cc-search-field {
  @apply flex flex-col h-full;
  .cc-grid {
    @apply flex flex-grow h-1 justify-center;
    .k-grid {
      @apply h-full max-h-full #{!important};
      min-height: 440px;
    }
  }
}
