/* General chat styling */
.chat-body {
  position: relative;
  background-color: #f9f9f9; /* Light background color */
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  .k-chat {
    height: 100%;
    width: 100%;
  }
}

/* Message list style */
.k-message-list {
  flex: 1; /* Fill available space */
  overflow-y: auto; /* Enable scrolling if messages overflow */
  margin-bottom: 10px; /* Space between messages list and input field */
}

/* Input area style */
.k-chat-input {
  display: flex;
  align-items: center;
  border-top: 1px solid #e0e0e0; /* Light top border */
  background-color: white; /* Input area background */
  padding: 10px; /* Padding for the input area */
}

/* Textarea input style */
.k-chat-textarea {
  flex: 1;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 10px;
  outline: none;
  resize: none; /* Prevent resizing */
  font-size: 14px; /* Adjust as needed */
  margin-right: 10px; /* Space between textarea and send icon */
}

/* Send icon style */
.k-chat-send-icon {
  cursor: pointer;
  color: #007bff; /* Change to desired color */
}

.k-chat-send-icon:hover {
  color: #0056b3; /* Change to desired hover color */
}

/* Message bubble styles (optional additional styling) */
.k-bubble {
  border-radius: 8px;
  padding: 10px;
  margin: 5px 0;
}

.k-bubble.k-user {
  background-color: #e1ffd4; /* Light green background for user messages */
}

.k-bubble.k-respondent {
  background-color: #f1f1f1; /* Light grey background for bot messages */
}
