.cc-grid-control-button {
  @apply flex mr-2;
  .cc-wrap-icon {
    @apply ml-1;
    padding: 5px 9px;
    border: 1px;
    border-radius: 4px;
    @apply text-warning;
    &:hover {
      @apply bg-expand-color;
      .cc-expand-icon,
      .cc-collapse-icon {
        & > .cc-icon-custom {
          @apply bg-expand-color;
        }
        & > i {
          @apply bg-expand-color text-warning #{!important};
        }
      }
    }
    & > div {
      @apply relative flex text-accent items-center cursor-pointer font-light;
      font-size: 14px;
      &:hover {
        @apply text-warning;
      }
      .cc-icon-custom {
        @apply absolute bg-body-bg;
        height: 45%;
        width: 4px;
      }
      .cc-float-left {
        left: -2px;
      }
      .cc-float-right {
        right: -2px;
      }
    }
  }
}
