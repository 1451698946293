.cc-details-description {
  @apply relative w-full h-auto overflow-hidden;
}

.cc-details-description-pre {
  @apply max-h-40 overflow-auto p-2 rounded;
  font-family: inherit;
  border: 1px solid var(--secondary);
  white-space: pre-wrap;
  &::after {
    content: "";
    @apply absolute rounded z-50 bottom-0 right-2 h-full w-full bg-secondary opacity-50;
  }
}

.cc-html-warning {
  @apply text-warning mt-1;
}

.cc-field.cc-communication-details {
  height: fit-content;
}
