.heading-title-block {
  padding-bottom: 10px;
  border-bottom: 2px solid #e9e8e8;
  .title-code {
    font-weight: 600;
  }
}
.cc-rating-star-wrapper {
  @apply flex flex-row gap-2;
  .cc-rating-star {
    @apply text-warning;
  }
}

.cc-premise-risk-class {
  @apply grid grid-cols-2;
  .cc-risk-class-label-value {
    @apply font-semibold text-success;
  }
}
