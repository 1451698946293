.cc-existed-setting-manage-child {
  @apply pb-3;
  .cc-cols-2 {
    @apply grid lg:grid-cols-2 grid-cols-1 gap-x-4 gap-y-6;
    .cc-col-sub-span-2 {
      @apply lg:col-span-2 col-span-1;
    }
    .cc-col-sub-span-1 {
      @apply col-span-1;
    }
  }
  .cc-panel-bar {
    @apply w-full mb-2 #{!important};
    .k-panelbar-header {
      @apply mb-2 #{!important};
    }
    .cc-collapse-header {
      @apply w-full cursor-pointer;
      h6 {
        @apply text-disabled capitalize #{!important};
      }
      hr {
        @apply w-full block border-disabled;
        border-width: 1px;
      }
    }
    .k-icon.k-i-chevron-up.k-panelbar-toggle.k-panelbar-collapse {
      @apply hidden;
    }
  }
  .k-animation-container.k-animation-container-relative {
    @apply pt-1;
  }
}
