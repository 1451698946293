.cc-custom-import-report {
  @apply list-decimal leading-6 -mt-3 ml-5 #{!important};
}
.cc-preview-report {
  @apply font-bold;
}
.cc-preview-report-ol {
  ol > li::marker {
    font-weight: bold;
  }
}
