.cc-error-dialog {
  @apply w-full h-full bg-no-repeat bg-bottom bg-contain flex flex-col justify-start items-center px-8;
  background-image: url("../../../assets/img/OpenOfficeLogo_Footer.png");
  h2 {
    @apply font-bold text-center;
  }
  p {
    @apply text-base font-medium text-center;
  }
  a {
    @apply text-base underline mt-4 mb-8 font-medium text-accent hover:text-warning;
  }
  span {
    @apply text-base font-medium text-accent hover:text-warning cursor-pointer;
  }
  .cc-error-notification {
    @apply w-full border-4 border-error flex  text-error font-medium;
    background-color: rgb(255, 215, 215);
    .fas {
      @apply p-4 bg-error text-white;
    }
    div {
      @apply p-4;
    }
  }
  .cc-logo-error-dialog {
    @apply h-52 w-52 m-4;
  }
}
