@tailwind components;
@layer components {
  .cc-contact-side-page {
    @apply w-full h-full space-y-2 overflow-y-auto p-4;

    .cc-contact-field {
      .cc-contact-link {
        @apply cursor-pointer;
      }
    }
    .cc-contact-card {
      @apply p-2 border border-border rounded;
      .cc-contact-field {
        @apply pt-1 text-disabled;
        .cc-contact-link {
          @apply text-accent no-underline hover:text-warning;
        }
        .cc-contact-link-preferred {
          @apply font-semibold;
        }
      }
      .cc-contact-field-title {
        .cc-contact-link {
          @apply font-medium text-base text-disabled;
        }
        .cc-contact-link.cc-contact-alert {
          color: red;
        }
      }
    }
  }
}
