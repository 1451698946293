// .cc-permits-disabled-question{
//     .cc-field-group{
//         @apply mt-3;
//     }
//     .cc-question{
//         @apply m-8;
//     }
//     .cc-text-error{
//         @apply text-error;
//     }
// }

.proprietor-selection-dialog {
  .text-overflow-ellipsis {
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .k-form-field {
    // margin-bottom: 0.1rem !important;
    margin-top: 0.1rem !important;

    .k-label {
      margin-bottom: 0.5rem;
    }
  }
}

.unactive-btn-link {
  text-decoration: none !important;
  cursor: not-allowed !important;
}

.cus-mt-2 {
  margin-top: 22px;
}
.cus-mt-3 {
  margin-top: 28px;
}

#contact-nar-dialog {
  .k-form {
    width: 100%;
    overflow: hidden;
  }
}
