.cc-smartphone {
  .cc-smartphone-screen {
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      bottom: -86px;
      right: 0;
      padding: 1.5rem 1rem 1rem;
      width: 100%;
      height: 96%;
    }
  }
}
