.cc-notification-container {
  @apply mb-2 w-full;

  .cc-message-container-success {
    background-color: var(--success);
    margin-bottom: 5px;
    border: 3px solid var(--success);
  }

  .cc-message-container-error {
    background-color: var(--error);
    border: 3px solid var(--error);
  }

  .cc-message-container-warning {
    background-color: var(--warning);
    border: 3px solid var(--warning);
  }

  .cc-message-container-info {
    background-color: var(--info);
    border: 3px solid var(--info);
  }
  .cc-notification {
    @apply w-full flex flex-row py-0;
    .cc-message-container-icon {
      @apply w-10 h-9 flex justify-center items-center text-lg text-white;
    }
    .cc-message-container-Inner {
      @apply w-full text-component bg-body-bg pl-2 pt-2 max-h-12 overflow-auto text-sm leading-snug;
    }
  }
}
