.cc-property-info-popup {
  @apply mt-1 shadow-xl #{!important};
  .cc-popup-content {
    @apply p-4 #{!important};
  }
}
.cc-property-info-icon {
  width: 14px !important;
  height: 14px !important;
  @apply ml-2 hover:text-warning #{!important};
  &.cc-icon-active {
    @apply text-warning #{!important};
  }
}
