.cc-pick-days-week {
  display: flex;
  column-gap: 5px;
  .cc-pick-days-week__item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 25px;
    border-radius: 10px;
    cursor: pointer;
    &:hover {
      background-color: var(--card-cap-bg);
    }
    &.active {
      background-color: var(--primary);
      color: var(--white);
    }
  }
}
