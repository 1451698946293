.cc-animal-kennel-owner-button {
  @apply p-0 pt-1 hover:text-success #{!important};
  width: 14px !important;
  height: 14px !important;
}
.cc-same-as-property {
  @apply flex flex-row gap-2;
}
.cc-contact:not(:last-of-type)::after {
  content: ", ";
}
