#scheduler-popup .k-popup {
  width: 250px;
  padding: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.03), 0 4px 5px 0 rgba(0, 0, 0, 0.04);
}
.event-scheduler {
  margin-top: 15px;
  padding: 10px;
}

.calendar-popup {
  overflow: hidden;
}

.k-dateinput,
.k-datepicker,
.k-datetimepicker,
.k-timepicker {
  // width: initial !important;
  width: 100%;
}
