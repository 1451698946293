.cc-new-setting-manage-form {
  .cc-label {
    @apply text-disabled inline-block mb-2;
  }
  .cc-edit-hidden {
    @apply hidden;
  }
  .cc-cols-3 {
    @apply grid lg:grid-cols-3 grid-cols-1 gap-x-4 gap-y-6;
  }
  .cc-none-group {
    @apply mt-3;
  }
  .cc-col-span-1 {
    @apply col-span-1;
  }
  .cc-col-span-3 {
    @apply lg:col-span-3 col-span-1;
  }

  .cc-panel-bar {
    @apply w-full mb-2 #{!important};
    .k-panelbar-header {
      @apply mb-2 #{!important};
    }
    .cc-collapse-header {
      @apply w-full cursor-pointer;
      h6 {
        @apply text-disabled capitalize #{!important};
      }
      hr {
        @apply w-full block border-disabled;
        border-width: 2px;
      }
    }
  }
}
