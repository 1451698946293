.cc-search-combobox-new {
  @apply flex items-end;
  .k-combobox {
    .k-dropdown-wrap,
    .k-select {
      @apply rounded-tr rounded-br #{!important};
    }
    .k-select {
      @apply bg-transparent text-disabled hover:text-accent #{!important};
    }
    .k-state-focused {
      @apply shadow-none #{!important};
    }
    .k-clear-value {
      @apply text-error;
    }
  }
}

.cc-property-address-picker-search {
  .k-popup {
    min-width: 800px;
    @apply py-0 overflow-x-hidden  #{!important};
    & > .k-list-scroller {
      @apply overflow-x-hidden;
      & > .k-list {
        & > .k-item {
          @apply p-0 #{!important};
        }
      }
    }
  }

  .k-table-header {
    padding-inline-end: 0px !important;
  }

  .cc-search-header {
    @apply flex bg-secondary w-full;

    & > div {
      @apply p-2;
    }
  }
  .cc-search-item {
    @apply flex w-full;
    & > div {
      @apply p-2 break-words;
    }
  }
  .k-list-scroller,
  .cc-search-header {
    min-width: 800px;
  }
}
@media (max-width: 1000px) {
  .cc-property-address-picker-search {
    .k-popup {
      @apply py-0 overflow-x-auto #{!important};
      min-width: 300px;
    }
  }
}
