.cc-custom-grid-column-menu-filter {
  .k-filter-menu {
    input {
      @apply mt-3;
    }
  }
}
.cc-group-filter-button {
  @apply flex items-center flex-wrap gap-2 mt-1 px-6 py-2;
  .cc-filter-menu-button {
    @apply text-sm flex-1 box-border border;
    line-height: inherit;
  }
}
