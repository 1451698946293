.cc-general-panel {
  @apply mb-4;
  & > .cc-panel-header {
    @apply border-b-2;
  }
  &.cc-sub-panel {
    & > .cc-panel-header {
      @apply mt-2 ml-3;
      border-bottom-width: 1px;
    }
    & > .cc-panel-body {
      @apply ml-3;
    }
  }
}
