.cc-document-group {
  grid-template-columns: 17rem 1fr;
  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
  .cc-field-custom {
    @apply mb-3;
  }
  @apply border-border border p-3 grid rounded;
  .cc-image-upload {
    @apply w-60 h-40  rounded relative;
    .cc-image-upload-control {
      @apply w-full h-full absolute  top-0 left-0 bg-gray-50 bg-opacity-0 opacity-0 flex items-center justify-center hover:bg-opacity-60 hover:opacity-100 transition duration-200 ease-in-out;
      .svg-inline--fa {
        @apply text-3xl cursor-pointer;
      }
      .fa-upload {
        @apply text-accent;
      }
      .fa-times {
        @apply text-error;
      }
    }
    .cc-image-review {
      @apply w-full h-full object-cover rounded;
    }
  }
  .cc-image-upload-container {
    @media (max-width: 1200px) {
      margin-bottom: 10px;
    }
    @apply flex items-center justify-center flex-1 flex-col;
    .k-dropzone {
      @apply bg-transparent p-0;
    }
    .k-upload-button {
      width: 240px;
      left: -120px;
      @apply absolute h-40 top-0 bg-transparent hover:shadow-none focus:shadow-none shadow-none hover:bg-transparent focus:bg-transparent z-menu border-0 border-transparent;
    }
    .cc-button-upload-file {
      @apply border-0 bg-transparent;
    }
    .cc-drop-here {
      @apply w-60 h-40 border-red-600;
      .k-dropzone-note {
        @apply text-center;
      }
    }
  }
  .cc-field-container {
    @apply col-span-1 grid;
  }
  .cc-image-col {
    @apply col-span-1 grid;
  }
  .cc-custom-note {
    color: #b71c1c;
  }
}
.cc-item-btn-close {
  @apply text-error absolute self-end mr-2 bg-opacity-0 bg-gray-200 border-none hover:bg-opacity-70  #{!important};
  width: 22px !important;
  height: 22px !important;
  margin-top: 6px;
}
.cc-document-group-container {
  @apply flex flex-col relative;
}
