.cc-journal {
  @apply self-start;
  @apply h-full;
  .infinite-scroll-component__outerdiv {
    @apply h-full #{!important};
    .cc-infinite-scroll {
      @apply p-4 #{!important};
    }
  }
  .cc-journal-end {
    @apply text-xs text-center text-disabled;
  }
  .cc-overwrite-loading {
    @apply h-auto #{!important};
  }
  .cc-timeline {
    @apply list-none relative m-0;
    &:before {
      @apply bg-disabled inline-block absolute  h-full z-20;
      content: " ";
      top: 10px;
      left: 10px;
      width: 2px;
    }
    .cc-timeline-item {
      @apply my-4 pl-8 pr-4;
    }
    .cc-timeline-header {
      @apply flex justify-between items-center;
      &:before {
        @apply bg-body-bg z-30 absolute inline-block rounded-full border-accent;
        content: " ";
        width: 14px;
        height: 14px;
        border-width: 3px;
        left: 4px;
        outline: 3px solid var(--white);
      }
      &:after {
        @apply bg-disabled inline-block absolute z-20 w-5;
        content: " ";
        left: 18px;
        height: 2px;
      }
      .cc-timeline-date {
        @apply text-sm bg-accent rounded-md text-white px-2 py-1 truncate z-30;
      }
    }
    .cc-timeline-user {
      @apply font-bold mr-2 pt-2 truncate;
    }
    .cc-timeline-title {
      @apply block text-component m-0 break-words whitespace-normal;
    }
    .cc-timeline-content {
      @apply block text-disabled break-words whitespace-normal;
    }
  }
}
