.cc-setting-collapsed {
  height: 0;
  overflow: hidden;
}
.cc-setting-expanded {
  @apply mt-2;
}
.cc-setting-button-container {
  @apply flex justify-between pb-0.5 items-end border-b cursor-pointer border-disabled;
  @apply hover:opacity-70 transition ease-in-out;
  height: 30px;
  .cc-edit-hidden {
    @apply hidden;
  }
  .cc-collapse-title {
    @apply cursor-pointer w-2/3 capitalize m-0 mb-1 text-disabled #{!important};
  }
  .cc-button-wrapper {
    @apply flex justify-end;
  }
  .cc-manage-button {
    @apply border-0 m-0 text-lg text-accent #{!important};
  }
  .cc-button-disabled {
    @apply text-disabled #{!important};
  }
  .cc-button-enabled {
    @apply text-success #{!important};
  }
  .cc-save-button {
    @apply mr-1 text-lg;
  }
  .cc-cancel-button {
    @apply text-error text-lg #{!important};
  }
  .cc-panel-title-invalid {
    @apply text-error #{!important};
  }
}
.cc-setting-bold-collapse {
  @apply border-b-2 #{!important};
}

.cc-setting-button-container.cc-panel-heading-line-invalid {
  @apply border-error #{!important};
}
