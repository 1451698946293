.cc-notification {
  @apply w-full flex flex-row mb-2;

  .cc-notification-icon {
    @apply w-10 h-9 flex justify-center items-center text-lg text-white;
  }
  .cc-icon-close {
    @apply w-10 flex justify-center items-center text-sm text-white;
  }
  .cc-notification-content {
    @apply w-full text-component bg-body-bg pl-2 pt-2 max-h-24 overflow-auto text-sm leading-snug;

    .k-panelbar .k-panelbar-header {
      .k-link span.k-panelbar-item-text {
        @apply cursor-pointer mb-5 text-component;
      }

      .k-animation-container ul.cc-notification-secondary-content li {
        @apply pb-2;
      }
    }
  }
  &.cc-notification-success {
    background-color: var(--success);
    border: 3px solid var(--success);
  }

  &.cc-notification-error {
    background-color: var(--error);
    border: 3px solid var(--error);
  }

  &.cc-notification-warning {
    background-color: var(--warning);
    border: 3px solid var(--warning);
  }

  &.cc-notification-information {
    background-color: var(--info);
    border: 3px solid var(--info);
  }
}
