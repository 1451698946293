.cc-candidates-dialog {
  @apply pb-4 #{!important};
  .cc-postfix-fixed-width {
    @apply w-52 xl:px-4 px-16 #{!important};
  }
}

.cc-label-field-inline-group {
  @apply flex flex-row flex-wrap;

  .cc-label-field-inline-group-left,
  .cc-label-field-inline-group-right {
    @apply mt-2 mb-2 mr-1 flex flex-row  flex-wrap;
    .cc-right-label {
      @apply mt-2 mb-2 mr-1;
      min-width: max-content;
    }
    .cc-left-label {
      @apply mt-2 mb-2 mr-1;
      min-width: max-content;
    }
    .cc-right-content {
      max-width: 100px;
    }
  }
}
