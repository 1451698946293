.cc-reference-sidebar {
  @apply relative;
  .reference-tabstrip {
    @apply relative;
    & > ul {
      @apply w-full overflow-x-auto;
      scroll-behavior: smooth;

      &::-webkit-scrollbar {
        @apply w-0 h-0 bg-transparent;
      }
    }
  }
  .cc-tabstrip-button {
    @apply absolute;
    @apply flex justify-center items-center;
    @apply w-8 h-8 z-10;
    @apply bg-component-bg;
    @apply text-accent hover:text-warning;
    @apply border border-border;
    @apply cursor-pointer;
    .fas {
      @apply text-lg;
    }
    &:after {
      @apply absolute;
      @apply w-1 h-8;
      @apply bg-component-bg;
      @apply border border-border border-t-0;
      content: "";
    }
    &.cc-tabstrip-next {
      @apply right-0;
      &:after {
        @apply -left-1 border-l-0;
      }
    }
    &.cc-tabstrip-prev {
      @apply left-0;
      &:after {
        @apply -right-1  border-r-0;
      }
    }
  }

  & > .k-tabstrip {
    & > .k-content {
      & > .k-animation-container {
        @apply h-full;
        .k-child-animation-container {
          @apply h-full;
          & > div {
            @apply h-full;
          }
          .cc-ref-map-container {
            @apply h-full w-full rounded;
          }
        }
      }
    }
  }
  .k-tabstrip-content,
  .k-tabstrip > .k-content {
    @apply p-0 #{!important};
  }
  .cc-overwrite-loading {
    @apply h-full;
  }
}
