.editable-field .disabled {
  pointer-events: none;
}

.editable-field .disabled:hover {
  cursor: not-allowed;
}

.editable-field .input-element {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.editable-field .input-group-append {
  margin-left: 3px;
}

.editable-field .input-group-text {
  padding: 0.355rem 0.75rem;
}
