.cc-flex-container {
  @apply flex;
  .cc-index-col,
  .cc-index-col-empty {
    @apply flex items-start justify-end;
    @apply mt-2 mr-3;
    min-width: 30px;
  }
  .cc-content-col {
    @apply flex-1;
    .cc-apply-button {
      @apply w-1/2;
    }
  }
}

.cc-finance-setting-sub-panel-bar {
  @apply pl-3 #{!important};
}

.cc-finance-setting-cols-3 {
  @apply grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-x-4 gap-y-4;
}

@media (max-width: 1024px) {
  .cc-flex-container {
    .cc-index-col-empty {
      @apply flex items-start justify-end;
      @apply m-0 min-w-0 #{!important};
      min-width: 30px;
    }
    .cc-index-col {
      min-width: 20px;
    }
    .cc-content-col {
      .cc-apply-button {
        @apply w-full #{!important};
      }
    }
  }

  .cc-finance-setting-sub-panel-bar {
    @apply pl-0 #{!important};
  }

  .cc-finance-setting-cols-3 {
    @apply inline-flex #{!important};
  }

  .cc-custom-export-title-field {
    @apply w-1/2 #{!important};
  }

  .cc-custom-export-date-format-field {
    @apply w-1/2 #{!important};
  }
}
