@keyframes ChangeOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.cc-dialog.cc-unset-min-height .k-dialog {
  min-height: unset !important;
}
.cc-dialog {
  h5 {
    font-size: 1.09375rem;
    color: var(--component-color);
  }
  .k-window-actions {
    @apply m-0;
  }
  @apply visible #{!important};
  .cc-dialog-body {
    @apply h-full flex flex-col;
  }
  .cc-dialog-footer {
    @apply p-1 flex justify-between sm:flex-row-reverse flex-col-reverse max-h-40 overflow-y-auto;
  }
  .cc-dialog-sub-message {
    @apply text-base text-disabled;
  }
  .cc-dialog-sub-message {
    p:last-child {
      @apply mb-0;
    }
  }
  .k-dialog > div::-webkit-scrollbar-thumb:hover {
    @apply bg-disabled rounded-full;
  }

  .k-dialog {
    animation: ChangeOpacity 0.2s ease-in;
    min-width: 270px;
    min-height: 270px;
    border-radius: 4px;
    .k-dialog-titlebar {
      @apply overflow-hidden;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      background: transparent;
      .k-dialog-title {
        @apply sm:h-auto sm:w-auto h-full w-full;
        .cc-dialog-title-header {
          @apply flex items-center flex-wrap whitespace-normal sm:h-auto sm:w-auto h-full w-full gap-1 overflow-auto;
          .cc-dialog-badge-list {
            @apply flex items-center flex-wrap gap-1;
          }
          .cc-dialog-badge-normal {
            @apply bg-yellow-500 bg-opacity-10 text-warning py-1 px-2 text-sm md:text-base font-bold rounded-full border-warning border;
          }
          .cc-dialog-badge-success {
            @apply bg-green-500 bg-opacity-10 text-success  py-1 px-2 text-sm md:text-base font-bold rounded-full border-success border;
          }
          .cc-dialog-badge-error {
            @apply bg-red-500 bg-opacity-10 text-error  py-1 px-2 text-sm md:text-base font-bold rounded-full border-error border;
          }
        }
      }
      .k-dialog-titlebar-actions {
        .k-dialog-titlebar-action {
          color: var(--cc-dialog-titlebar-action-icon-close-color);
          &:hover {
            color: var(--cc-dialog-titlebar-action-icon-close-color-hover);
          }
        }
      }
    }
  }
  .cc-overwrite-loading {
    @apply h-full;
  }
}
.cc-dialog-disabled {
  .k-window-content,
  .k-window-actions {
    @apply pointer-events-none #{!important};
  }
  .cc-field,
  .k-actions,
  .k-window-actions {
    @apply opacity-60 #{!important};
  }
}

@media screen and (max-width: 639.8px) {
  .cc-dialog .k-dialog-title {
    @apply max-h-40;
    min-height: 64px;
  }
}
