.cc-pick-address-dialog {
  @apply mb-4 pb-4 space-y-4;
  .cc-pick-address-form-row {
    @apply flex  items-end;
    .cc-pick-address-field {
      @apply w-4/5 md:w-5/6;
    }
    .cc-pick-address-field-submit {
      @apply w-2/6 md:w-1/6 pl-3;
      .k-button {
        @apply w-full;
      }
    }
  }
  .cc-grid-view {
    @apply w-full;
    min-height: 200px;
    .cc-grid-col {
      @apply truncate;
    }
  }
}
