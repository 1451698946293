#manage-inspection {
  .active-menu-action {
    font-weight: bold;
  }

  .hight-light-color {
    color: var(--warning);
  }

  // custom panelbar
  .k-panelbar {
    .k-item {
      border: none;
    }
    border: none;
    background-color: transparent;
    .k-item.k-state-default {
      span.k-link.k-header {
        padding: 0px;
        border-bottom: var(--component-color);

        .k-icon.k-i-arrow-s,
        .k-i-arrow-n {
          visibility: hidden;
        }
      }
      span.k-link {
        border: none;
        background-color: transparent;
      }

      .k-state-selected {
        border: none;
        // background-color: transparent !important;
      }

      .k-state-focused {
        box-shadow: none;
      }
    }
  }

  .border {
    border: 1px solid var(--secondary);
  }

  .k-content {
    background-color: transparent;
  }

  hr {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .k-animation-container-relative {
    display: block;
  }

  .tab-side-link {
    text-decoration: none;
    color: var(--component-color);
  }

  .tab-side-link:hover {
    text-decoration: none;
  }

  .k-dateinput,
  .k-datepicker,
  .k-datetimepicker,
  .k-timepicker {
    border: none;
  }

  .k-tabstrip-items {
    overflow-y: hidden;
  }
}
