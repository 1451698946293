.cc-setting-list-option {
  .cc-error-mandatory {
    .k-grid {
      border-color: #b71c1c;
    }
  }
  .k-widget.k-combobox.cc-grid-multi-selection-combobox {
    @apply inline-block;
  }
  .k-label {
    @apply mb-0 #{!important};
  }
  .cc-list-flex {
    @apply flex;
  }
  .cc-list-field-grid {
    height: 200px;
    @apply rounded #{!important};
    .k-grid-toolbar {
      @apply p-0 bg-transparent #{!important};
    }
    .cc-mr-checkbox {
      @apply mr-3;
    }
  }
  .cc-error-list {
    @apply border-error;
  }
  .cc-list-field-toolbar {
    @apply py-1 px-3 h-7 w-full flex justify-end items-center;
    .cc-manage-button {
      @apply border-0 border-r m-0 text-lg text-accent #{!important};
    }
    .cc-vertical {
      @apply border-r border-secondary pr-2 mr-2;
    }
  }
}
