.cc-action-bar-nav-dropdowns {
  @apply flex items-center flex-wrap;
  @apply md:flex-row flex-col;
  @apply md:space-x-1 space-x-0 md:space-y-0 space-y-1;
  @apply md:w-auto w-full;
}
.cc-action-bar-multi-select {
  @apply w-36 #{!important};
  @apply md:ml-1 md:mt-0 mt-1 ml-0 #{!important};

  .k-multiselect {
    .k-multiselect-wrap {
      @apply text-accent;
      height: 28px;
      .k-button {
        @apply bg-transparent text-accent;
        margin: 2px;
      }
    }
  }
}

.cc-navbar-dropdown,
.cc-navbar-dropdown-tree {
  @apply bg-transparent md:w-auto w-full #{!important};
  min-width: 160px;
  max-width: none !important;
  .k-input,
  .k-icon {
    @apply text-accent text-sm #{!important};
  }
  .k-input {
    @apply truncate block leading-4 #{!important};
  }
  .k-dropdown-wrap {
    @apply bg-transparent #{!important};
  }
}
.cc-navbar-dropdown-tree {
  .k-clear-value {
    @apply hidden;
  }
}
@media (min-width: 768px) {
  .cc-action-bar-nav-dropdowns {
    .cc-navbar-dropdown,
    .cc-navbar-dropdown-tree {
      max-width: 300px !important;
    }
  }
}

/* ====  UPGRADE KENDO NEW VERSION ^5.11.0  ==== */
.cc-action-bar-nav-dropdowns {
  .k-input-inner {
    @apply text-accent;
  }
}
.cc-navbar-dropdown,
.cc-navbar-dropdown-tree {
  border-color: var(--border-color) #{!important};
}

// cc-navbar-dropdown
@media all and (max-width: 767px) {
  .cc-drop-down-list-wrap {
    @apply w-full;
    .k-list-item {
      @apply py-3 #{!important};
    }
  }
}
.cc-drop-down-list-wrap {
  .k-list-ul {
    @apply py-2 #{!important};
    .k-list-item {
      height: 22px;
      &.k-selected {
        background-color: var(--accent) !important;
        color: var(--cc-dropdown-color) !important;
      }
    }
  }
}

// Dropdown
@media all and (max-width: 767px) {
  .cc-drop-down-tree-wrap {
    @apply w-full;
  }
  .cc-popup-navbar-dropdown-tree {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0;
  }
  .k-treeview-leaf {
    @apply py-3 #{!important};
  }
}

.cc-drop-down-tree-wrap {
  > .k-animation-container {
    min-width: 350px;
    z-index: 101 !important;
  }
  .k-animation-container {
    .k-treeview-mid-:hover,
    .k-treeview-top:hover,
    .k-treeview-bot:hover {
      background-color: var(--cc-grid-treeview-bg) !important;
    }
  }
  .k-treeview-mid {
    &:hover {
      background-color: unset;
    }
  }
}
.cc-popup-navbar-dropdown-tree {
  max-height: 500px;
  border-radius: 4px;
  @apply w-auto #{!important};
  .k-treeview {
    @apply py-2 overflow-x-hidden;
    .k-treeview-lines {
      .k-treeview-item {
        @apply pl-0 #{!important};
        &[aria-selected="true"] {
          background-color: var(--accent) !important;
          > .k-treeview-top,
          > .k-treeview-mid,
          > .k-treeview-bot {
            &:hover {
              background-color: unset !important;
            }
          }
        }
        .k-treeview-top,
        .k-treeview-mid,
        .k-treeview-bot {
          @apply pr-2 #{!important};
          &:hover {
            background-color: var(--cc-grid-treeview-bg);
          }
          .k-treeview-leaf {
            @apply flex-1 cursor-pointer pr-0;
            padding-top: 0.5px;
            padding-bottom: 0.5px;
            &:hover {
              background-color: unset !important;
            }
            &.k-focus {
              box-shadow: none !important;
            }
            &.k-selected {
              background-color: unset !important;
              color: var(--cc-dropdown-color) !important;
            }
            .k-treeview-leaf-text {
              @apply py-px;
              white-space: break-spaces;
            }
          }
          .k-treeview-toggle {
            @apply absolute right-1;
            .k-i-caret-alt-down {
              @apply transform rotate-180;
            }
            .k-i-caret-alt-right {
              @apply transform rotate-90;
            }
          }
        }
        .k-animation-container {
          @apply pl-2;
          // .k-treeview-item {
          //   @apply flex;
          // }
        }
      }
    }
  }
}

.cc-action-bar-nav-dropdowns {
  .k-dropdownlist,
  .k-dropdowntree {
    .k-input-inner {
      .k-input-value-text {
        color: var(--accent) !important;
      }
    }
  }
}

.cc-app-action-bar {
  .cc-nav-button.cc-nav-link {
    font-size: 14px;
  }
}
/* ====  / UPGRADE KENDO NEW VERSION ^5.11.0  ==== */
