.cc-common-form-navigation {
  @apply flex items-center space-x-4;
  .cc-rounded-back-button {
    @apply rounded-full text-body-bg hover:bg-info;
    background-color: #68b8cf;
    .fal {
      @apply font-bold;
      @media (min-width: 2400px) {
        margin-bottom: 1px;
      }
      @media (min-width: 5000px) {
        margin-bottom: 0px;
      }
    }
  }

  .cc-form-title {
    @apply m-0 text-disabled;
    font-size: 1.09375rem;
  }

  .cc-list-badge {
    @apply flex gap-x-1;
  }
}
