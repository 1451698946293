.k-item.k-state-selected,
.k-list-optionlabel.k-state-selected {
  width: 100%;
}
.custom-form-cols-4 {
  @apply grid gap-x-4 gap-y-4;
  grid-template-columns: 15px 1fr 1fr 1fr;
}
.custom-form-cols-2 {
  @apply grid grid-cols-2 gap-x-4 gap-y-4;
}
.custom-form-cols-3 {
  @apply grid grid-cols-3 gap-x-4 gap-y-4;
}
.align-selfEnd {
  align-self: flex-end;
}
.custom-label {
  text-align: right;
  align-self: end;
}
.custom-button-minwidth {
  min-width: 45px;
}
.custom-nummeric-minwidth {
  min-width: 72px;
}
