.contact-manager {
  .k-dialog-content {
    @apply h-full;
    .cc-dialog-body {
      @apply flex flex-col h-full;
      .cc-grid-search {
        min-height: 200px;
        @apply flex flex-col h-full;
        .cc-grid-search-input {
          @apply mb-4 flex-grow-0;
          .k-textbox {
            @apply w-full;
          }
        }
        .cc-grid-search-list {
          @apply flex flex-grow h-full justify-center;
          .cc-grid {
            .k-grid {
              @apply h-full max-h-full #{!important};
              min-height: 200px;
            }
          }
        }
      }
    }
  }
}
