@media (max-width: 576px) {
  /***** ACTIVITIES - Mobile *****/
  .flex-sm-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    flex-wrap: wrap;
  }

  .cc-activity-group .cc-activity-item {
    padding: 10px;
    width: calc(50% - 8px);
    height: 120px;
    margin: 4px;
    border-radius: 1.4pt;
    box-shadow: #3333338c 2px 2px 4px -2px;
  }

  .cc-activity-group .cc-activity-group-title {
    padding: 6px 10px;
  }

  /***** Table text size - Mobile *****/
  .k-grid {
    font-size: 0.8rem !important;
  }

  /*******Premises Register*********/

  .k-pager-wrap.k-pager.k-widget.k-pager-sm {
    padding-bottom: 22px;
  }

  #pager-selection-counter {
    // left: calc(100% - 150px)
    left: 8px;
    bottom: 2px;
  }
}
