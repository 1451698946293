.cc-event-section {
  @apply py-2;
  .cc-section-content {
    @apply grid gap-9 grid-cols-1 md:grid-cols-3 #{!important};

    .cc-event-title {
      @apply pb-1;
    }
    .cc-event-grid {
      @apply w-full overflow-auto h-52;
      .k-grid {
        @apply h-full #{!important};
      }
    }
    .link-button {
      @apply h-3 font-bold;
      padding: 0px !important;
      color: var(--warning) !important;
    }
    .link-button:hover {
      background: initial !important;
    }
    .cc-event-link-to {
      color: var(--accent) !important;
    }
  }
}
