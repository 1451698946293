.cc-custom-panel-bar {
  @apply w-full #{!important};
  .k-panelbar-header:not(:last-child) {
    @apply mb-4 #{!important};
  }
  .k-panelbar-header:first-child {
    @apply mb-4 #{!important};
  }
  .cc-collapse-header {
    @apply w-full cursor-pointer;
    h6 {
      @apply text-disabled #{!important};
    }
    hr {
      @apply w-full block border-disabled m-0 #{!important};
      border-width: 1px !important;
    }
  }
  .cc-custom-panel-body {
    @apply pt-4;
  }
  .cc-title-right {
    @apply flex flex-row justify-between;
    h6 {
      @apply flex flex-row self-end;
    }
    button {
      font-size: 14px;
    }
    .cc-panel-header-total-record {
      @apply font-bold text-xs text-disabled truncate;
      @apply border border-border px-2 rounded-3xl;
      padding-bottom: 1px;
    }
  }
}
