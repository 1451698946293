.ncc-product-list-view-notification {
  @apply flex-grow-0 mt-2;
  .cc-manage-notification {
    @apply pr-0;
  }
}
.ncc-product-list-view-body {
  @apply flex-grow flex flex-col lg:flex-row overflow-hidden py-2;
  height: 1%;
}
.ncc-product-list-view-filter {
  @apply lg:w-1/4 w-full flex-grow-0  lg:h-full h-1/4  overflow-y-auto lg:mr-2 mr-0 lg:mb-0 mb-2;
}
.ncc-product-list-view-grid {
  @apply flex-grow lg:w-2/4 w-full lg:h-full h-3/4;
}
.ncc-product-list-view-slide-bar {
  @apply flex justify-center items-center;
  @apply lg:w-1/4 w-full lg:flex-grow-0 h-full lg:ml-2 ml-0 lg:relative fixed top-0 left-0 z-50 lg:p-0 p-6;
  .ncc-product-list-view-slide-bar-card {
    @apply z-40 bg-body-bg w-11/12 h-5/6  p-2 rounded lg:p-0 lg:rounded-none lg:relative lg:w-full lg:h-full lg:z-auto;
    .cc-reference-sidebar {
      @apply h-full;
      .k-tabstrip {
        @apply h-full;
      }
    }
  }
  .ncc-product-list-view-slide-bar-backdrop {
    @apply lg:hidden absolute top-0 left-0 z-30 h-full w-full;
    background-color: rgba(17, 17, 17, 0.5) !important;
    opacity: 1 !important;
    backdrop-filter: blur(5px) !important;
    -webkit-backdrop-filter: blur(5px) !important;
  }
}
