.cc-login-page {
  @apply bg-gray-900 flex justify-center items-center min-h-screen w-full bg-cover bg-no-repeat;
  background-image: url("../../../assets/img/bg-login.png");
  .cc-login-notification {
    max-width: 400px;
    margin-top: 32px;
    .cc-notification-icon {
      @apply h-auto;
    }
    .cc-notification-content {
      @apply p-2;
    }
  }
  .cc-login-card {
    @apply bg-body-bg space-y-4  shadow-xl py-14 px-6 lg:px-36 md:px-24;
    & > .cc-app-notification-container {
      width: 400px;
    }
    .cc-login-header {
      @apply flex items-center justify-center space-x-4 mb-6;
      .cc-login-logo {
        @apply w-16 h-16;
      }
      .cc-login-company-name {
        @apply text-2xl font-medium;
      }
    }
    .cc-login-form-fieldset {
      @apply space-y-4;
      .cc-login-form-label {
        @apply text-disabled mb-1;
        span {
          @apply text-error;
        }
      }
      .cc-login-inline-group {
        @apply flex flex-wrap md:space-y-0 space-y-2 md:space-x-2 items-end justify-between;
        .cc-login-switch-container {
          @apply flex flex-col  space-y-1;
          .cc-login-switch {
            @apply flex space-x-2 items-center font-semibold;
          }
          label {
            @apply mb-0 text-disabled;
          }
        }
        .cc-login-button {
          @apply w-full md:w-60 p-2;
        }
        .cc-login-button-icon {
          @apply mr-2;
        }
        .cc-login-button-icon {
          @apply mr-2;
        }
      }
    }
    .cc-login-card-footer {
      @apply text-right;
      .cc-login-link {
        @apply text-accent hover:text-warning hover:no-underline;
      }
    }
  }

  .k-label {
    @apply -top-1 #{!important};
  }
  .k-form {
    min-width: 290px;
  }

  @media (min-width: 768px) {
    .k-form {
      min-width: 400px;
    }
  }

  @media (max-width: 767px) {
    .cc-login-card {
      max-width: 339.52px;
      min-height: 510px;
    }
  }
}
