.cc-year-dropdown-container {
  @apply pl-1;
  .cc-year-dropdown {
    @apply bg-transparent;
    min-width: 80px;
    .k-input-value-text {
      @apply text-accent;
    }
  }
}

.cc-year-dropdown-popup {
  @apply z-menu #{!important};
}
