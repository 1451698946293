.cc-nav-button {
  @apply hover:text-warning text-accent #{!important};
  @apply bg-transparent focus:shadow-none border-none #{!important};
  @apply p-0 m-0;
  @apply whitespace-nowrap leading-4 #{!important};
  @apply text-left #{!important};
  @apply gap-1 #{!important};

  .cc-nav-arrow-icon {
    @apply transition ease-in-out duration-300;
  }
}
.cc-nav-button-disabled {
  @apply text-disabled cursor-default hover:text-disabled pointer-events-none #{!important};
}

.cc-nav-button-active {
  @apply font-medium #{!important};
  .cc-nav-title {
    text-decoration: underline 1.5px !important;
    text-underline-offset: 5px !important;
  }
  .cc-nav-arrow-icon {
    @apply transform rotate-180;
  }
}

.cc-nav-icon-button {
  @apply text-accent hover:text-warning px-2 #{!important};
}
.cc-nav-icon-button-disabled {
  @apply text-disabled cursor-default hover:text-disabled pointer-events-none;
}
.cc-nav-icon-button-active {
  @apply text-warning #{!important};
}

.cc-nav-sub-menu {
  @apply hd:invisible visible flex;
  @apply flex-col;
  @apply justify-start items-start;
  @apply pl-2;
}

.cc-nav-more-slide {
  animation: slide-animation 0.5s forwards;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

@keyframes slide-animation-desktop {
  0% {
    width: 0%;
    overflow: hidden;
  }
  100% {
    width: 100%;
    overflow: hidden;
  }
}

@keyframes slide-animation {
  0% {
    height: 0%;
    overflow: hidden;
  }
  100% {
    height: 100%;
    overflow: hidden;
  }
}

.cc-nav-type-more,
.cc-nav-more-menu,
.cc-nav-more-children {
  @apply flex;
  @apply hd:justify-start hd:items-center justify-start items-start;
  @apply hd:flex-row flex-col;
}

.cc-nav-type-more {
  @apply border-border;
  @apply hd:border-l hd:border-t-0 border-t border-l-0;
  @apply hd:pl-2 hd:pt-0 pl-0 pt-2;
  @apply hd:ml-2 hd:mt-0 ml-0 mt-2;
  &:has(.cc-nav-more-children:empty) {
    @apply hidden #{!important};
  }
}
.cc-nav-type-sub,
.cc-nav-type-sub-workflow {
  .cc-nav-arrow-icon {
    @apply hd:hidden inline-flex;
  }
}

.cc-nav-type-sub-workflow {
  @apply border-border;
  @apply hd:border-r hd:border-b-0 border-b border-r-0;
  @apply hd:pr-2 hd:pb-0 pr-0 pb-2;
  @apply hd:mr-2 hd:mb-0 mr-0 mb-2;
}

.cc-nav-dropdown-menu {
  @apply hd:absolute  z-20 hd:p-2 hd:-mt-1 #{!important};
  @apply invisible h-0 w-0  #{!important};
  @apply hd:p-2 pl-2;
  @apply hd:border-solid hd:border-2 hd:border-component-bg;
  @apply hd:w-auto hd:h-auto hd:bg-secondary;
  @apply hd:shadow-md hd:rounded-sm;
  @apply transition-max-height duration-300;

  @apply max-h-0 overflow-hidden;
}
.cc-nav-dropdown-menu-active {
  @apply visible h-auto w-auto #{!important};
  @apply max-h-96;
}

.cc-nav-more-menu {
  .cc-close-button {
    @apply text-accent hover:text-warning #{!important};
  }
}
.cc-nav-button-container {
  &.cc-dialog-visible {
    &:hover {
      .cc-nav-dropdown-menu {
        @apply hd:invisible hd:h-0 hd:w-0  #{!important};
        @apply hd:max-h-0;
      }
    }
  }
  & > .k-animation-container {
    z-index: inherit !important;
  }
  .cc-nav-sub-menu-animation {
    @apply hd:invisible visible hd:w-0 hd:h-0 w-auto h-auto #{!important};
  }
  &:hover {
    .cc-nav-dropdown-menu {
      @apply hd:visible hd:h-auto hd:w-auto  #{!important};
      @apply hd:max-h-96;
    }
  }
}
.cc-nav-link {
  @apply hover:no-underline hover:text-warning text-accent;
}
//Customize button
body {
  .cc-workflow-button {
    @apply rounded-none hover:text-accent text-warning #{!important};
  }
}

@media (min-width: 1366px) {
  .cc-nav-more-slide {
    animation: slide-animation-desktop 0.5s forwards;
  }
}
