.cc-old-reference-sidebar {
  @apply relative;
  .reference-tabstrip {
    position: relative;
  }

  .reference-tabstrip > ul {
    width: 100%;
    overflow: visible;
  }

  .reference-tabstrip > ul:after {
    content: "";
  }

  .reference-tabstrip > ul.hidden,
  ul.start {
    padding-left: 0px;
  }

  .reference-tabstrip > ul.hidden:after {
    padding-right: 0px;
  }

  .tabstrip-next {
    @apply absolute text-lg bg-body-bg z-10 text-accent flex items-center justify-center cursor-pointer hover:text-warning rounded border border-border;
    right: 0;
    width: 30px;
    height: 30px;
    &:before {
      @apply bg-body-bg absolute;
      width: 5px;
      height: 31px;
      top: -1px;
      left: -6px;
      content: " ";
    }
  }

  .tabstrip-next.hidden {
    display: none;
  }

  .tabstrip-prev.hidden {
    display: none;
  }

  .tabstrip-prev {
    @apply absolute text-lg bg-body-bg z-10 text-accent flex items-center justify-center cursor-pointer hover:text-warning rounded border border-border;
    left: 0;
    width: 30px;
    height: 30px;
    &:before {
      @apply bg-body-bg absolute;
      width: 5px;
      height: 31px;
      top: -1px;
      right: -6px;
      content: " ";
    }
  }

  .reference-tabstrip > ul {
    overflow: auto;
    scroll-behavior: smooth;
  }
  .reference-tabstrip > ul::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background: inherit;
  }

  .reference-tabstrip > ul::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.4);
  }
  .reference-tabstrip > ul::-webkit-scrollbar-track:vertical {
    border-right: 2px solid rgba(0, 0, 0, 0.2);
  }
  .reference-tabstrip > ul::-webkit-scrollbar-thumb:vertical {
    border-right: 2px solid rgba(255, 255, 255, 0.2);
  }
  .reference-tabstrip > ul::-webkit-scrollbar-track:horizontal {
    border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  }
  .reference-tabstrip > ul::-webkit-scrollbar-thumb:horizontal {
    border-bottom: 2px solid rgba(255, 255, 255, 0.2);
  }
  .reference-tabstrip > ul::-webkit-scrollbar-corner,
  .reference-tabstrip > ul::-webkit-resizer {
    background: inherit;
    border-right: 2px solid rgba(255, 255, 255, 0.2); //optional
    border-bottom: 2px solid rgba(255, 255, 255, 0.2); //optional
  }
  & > .k-tabstrip {
    & > .k-content {
      & > .k-animation-container {
        @apply h-full overflow-y-auto px-4 py-2;
        .k-child-animation-container {
          @apply h-full;
          & > div {
            @apply h-full;
          }
          .cc-ref-map-container {
            @apply h-full w-full py-2;
          }
        }
      }
    }
  }
  .k-tabstrip-content,
  .k-tabstrip > .k-content {
    @apply p-0 #{!important};
  }
}
