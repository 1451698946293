.cc-form-step {
  @apply flex h-full;
  .k-step-text {
    @apply py-1 lg:block hidden #{!important};
    max-width: calc(14em - (16px + 0.5rem)) !important;
  }
  .cc-stepper {
    @apply pr-3 border-r border-border lg:w-48 w-14;
    .k-progressbar[aria-valuemax="0"] {
      @apply hidden;
    }
  }
  .cc-steps-content {
    @apply flex-grow w-5/6 overflow-y-auto pl-4 pb-1;
    @apply flex flex-col;
    .cc-overwrite-loading {
      @apply h-full;
    }
    .cc-result {
      &.cc-result-error {
        &.cc-load-failed {
          @apply h-full;
        }
      }
    }
  }
  .cc-form {
    &.cc-form-load-failed {
      @apply flex flex-col h-full;
      > .cc-result {
        &.cc-result-error {
          &.cc-load-failed {
            @apply h-full;
          }
        }
        h2 {
          @apply font-bold text-sm leading-0 md:font-normal md:text-2xl;
        }
        h6 {
          @apply text-sm md:text-xl;
        }
        .cc-result-icon {
          font-size: 2rem;
          line-height: 0.5;
          @apply md:text-7xl;
        }
      }
    }
    &.cc-form-loading {
      @apply flex flex-col h-full;
      > .cc-overwrite-loading {
        @apply h-full;
      }
    }
  }
  .cc-steps-title {
    @apply lg:hidden block mb-3 mt-1 text-lg text-accent font-medium;
  }
  .cc-steps-content .cc-form {
    @apply overflow-auto pr-4;
  }
}
