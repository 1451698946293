.cc-breadcrumb {
  @apply pl-1 flex flex-row items-center;
  padding-top: 10px;
  padding-bottom: 10px;
  .cc-back-button {
    border-radius: 9999px;
    color: var(--body-bg);
    background-color: #68b8cf !important;
    padding: 0.375rem;
    width: calc(1em + 0.75rem + 2px);
    height: calc(1em + 0.75rem + 2px);
    grid-gap: 0;
    gap: 0;
  }
  .cc-nav-breadcrumb {
    align-self: center;
    a {
      font-weight: 600;
    }
  }
  .fal {
    @apply font-bold;
    @media (min-width: 2400px) {
      margin-bottom: 1px;
    }
    @media (min-width: 5000px) {
      margin-bottom: 0px;
    }
  }
  .cc-breadcrumb-items {
    @apply flex flex-row items-center;
  }
  .cc-breadcrumb-item + .cc-breadcrumb-item::before {
    content: ">";
    color: var(--disabled);
    font-size: 0.99rem !important;
    @apply opacity-80 pr-2;
  }
  .cc-breadcrumb-item {
    @apply ml-3;
    color: var(--disabled);
    opacity: 0.8;
    font-weight: 600;
    font-size: 0.99rem !important;

    a {
      color: var(--accent);
    }
  }
}
