.cc-reset-password-page {
  .cc-reset-password-notification {
    max-width: 400px;
    .cc-notification-icon {
      @apply h-auto;
    }
    .cc-notification-content {
      @apply p-2;
    }
  }
  @apply bg-gray-900 flex justify-center items-center min-h-screen w-full bg-cover bg-no-repeat;
  background-image: url("../../../assets/img/bg-login.png");
  .cc-reset-password-card {
    min-height: 470px;
    @media (max-width: 500px) {
      min-width: 100vw;
    }
    & > .cc-app-notification-container {
      width: 400px;
    }
    @apply bg-body-bg space-y-4  shadow-xl py-14 px-6 lg:px-36 md:px-24;
    .cc-reset-password-header {
      @apply flex items-center justify-center space-x-4 mb-6;
      .cc-reset-password-logo {
        @apply w-16 h-16;
      }
      .cc-reset-password-company-name {
        @apply text-2xl font-medium;
      }
    }
    .cc-reset-password-form-fieldset {
      @apply space-y-4;
      .cc-reset-password-form-label {
        @apply text-disabled mb-1;
        span {
          @apply text-error;
        }
      }
      .cc-reset-password-inline-group {
        @apply flex flex-wrap md:space-y-0 space-y-2 md:space-x-2 items-end pt-0 md:pt-5;
        .cc-reset-password-switch-container {
          @apply flex flex-col  space-y-1;
          .cc-reset-password-switch {
            @apply flex space-x-2 items-center font-semibold;
          }
          label {
            @apply mb-0 text-disabled;
          }
        }
        .cc-reset-password-back {
          @apply md:w-60 p-2;
          width: 50px;
          height: 32px;
        }
        .cc-reset-password-button {
          @apply flex-1 md:w-60 p-2;
          width: 100% !important;
        }
        .cc-reset-password-button-icon {
          @apply mr-2;
        }
      }

      .cc-reset-password-inline-group-ok {
        @apply flex flex-wrap md:float-right md:space-y-0 space-y-2 md:space-x-2 items-end pt-0 md:pt-5;
        .cc-reset-password-button-ok {
          @apply w-full md:w-60 p-2;
        }
      }
    }
    .cc-reset-password-card-footer {
      @apply text-right;
      .cc-reset-password-link {
        @apply text-accent hover:text-warning hover:no-underline;
      }
    }
  }

  .k-label {
    @apply -top-1 #{!important};
  }
  .k-form {
    min-width: 290px;
    @media (max-width: 500px) {
      min-width: 80vw;
    }
  }

  @media (min-width: 768px) {
    .k-form {
      min-width: 400px;
    }
  }
}
