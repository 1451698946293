.cc-grid {
  @apply w-full h-full;
  .cc-grid-container {
    .k-pager.k-pager-md > .k-pager-sizes > .k-dropdownlist {
      background-color: var(--body-bg);
    }

    @apply w-full h-full;
    .cc-disabled-row,
    .cc-disabled-row-can-select {
      .cc-grid-link-text,
      .cc-grid-link-text a {
        @apply text-disabled #{!important};
      }
    }

    .cc-disabled-row-can-select {
      td:not(.cc-checkbox-locked) {
        @apply text-disabled pointer-events-none opacity-80  #{!important};
      }
      .cc-checkbox-locked {
        @apply text-disabled opacity-80  #{!important};
      }
    }

    .cc-disabled-row-can-not-select {
      // Disable row
      @apply text-disabled pointer-events-none opacity-80  #{!important};
      .k-hierarchy-cell {
        @apply opacity-50;
      }
    }

    .cc-cost-grid {
      @apply text-right;
    }
    .cc-grid-selection-column-header {
      .k-i-more-vertical {
        &::before {
          opacity: 0.7;
          content: "\e006" !important;
        }
      }
    }
    .cc-grid-link-text {
      @apply text-accent hover:underline  cursor-pointer #{!important};
    }
    .cc-grid-disable-text {
      @apply text-disabled #{!important};
    }
    .cc-grid-multi-selection {
      @apply absolute top-2/4;
      transform: translateY(-50%);
      .cc-grid-multi-selection-combobox {
        .k-dropdown-wrap {
          @apply bg-transparent p-1;
          .k-icon {
            @apply text-disabled;
          }
        }
      }
    }
  }
  .cc-load-failed {
    @apply relative;
    @apply w-full h-full;
    @apply bg-component-bg p-4;
  }

  .cc-grid-no-records {
    @apply text-center;
    padding: 15px;
  }

  .k-loading-mask {
    @apply bg-component-bg;
    @apply z-grid-loading-mask #{!important};
  }

  .cc-grid-is-loading::after {
    content: "";
    @apply absolute top-0 left-0 bg-transparent w-full h-full;
    min-height: 80px;
  }

  .k-virtual-content {
    &.cc-grid-has-error {
      @apply overflow-hidden #{!important};
      &:has(.cc-load-failed) {
        .k-grid-table-wrap {
          @apply hidden;
        }
      }
    }
  }
}

/* ====  UPGRADE KENDO NEW VERSION ^5.11.0  ==== */
@media all and (max-width: 1024px) {
  .cc-grid {
    .k-pager-input.k-label {
      @apply hidden;
    }
  }
}
@media all and (max-width: 768px) {
  .cc-grid {
    .k-pager-sizes {
      @apply hidden;
    }
  }
}

@media (max-width: 767px) {
  .cc-grid {
    // adjust header drag in mobile
    .k-grouping-header {
      @apply flex-wrap;
    }
  }
}

@media (min-width: 768px) {
  .cc-grid {
    // adjust header drag
    .k-grouping-header {
      @apply flex-nowrap;
    }
  }
}

.cc-grid {
  .k-grid {
    .k-grid-container ~ .k-pager {
      border-width: 1px 0 0;
    }
    .k-table-th,
    td,
    .k-table-td {
      // repeat the following style (produced by Kendo) to resolve issue with bundled versions (production)
      // $kendo-grid-cell-vertical-border-width = 1px
      border-inline-start-width: 1px;
    }
  }
  .k-grid-header-wrap {
    // 2 following properties should apply together to prevent unequal width
    // between grid-head and grid-body
    @apply border-r-0;
    margin-inline-end: 0;
  }
  .text-overflow-ellipsis {
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .k-disabled.k-pager-nav,
  .k-pager-nav {
    color: var(--accent);
  }
  /* ====  Col sticky  ==== */
  tr.k-master-row {
    td {
      // adjust checkbox position
      &:nth-child(1) input {
        vertical-align: inherit;
      }
    }
    /* ====  ODD COLUMN  ==== */
    &:not(.k-table-alt-row) {
      td.cc-checkbox-locked {
        background-color: var(--cc-grid-col-odd-sticky-checkbox-bg);
      }
      td.cc-background-color-locked {
        background-color: var(
          --cc-grid-col-odd-sticky-no-checkbox-bg
        ) !important;
      }
      td:not(.k-grid-content-sticky):not(.k-group-cell) {
        background-color: var(--cc-grid-col-odd-bg);
      }
    }
    /* ====  / ODD COLUMN  ==== */

    /* ====  EVEN COLUMN  ==== */
    &.k-table-alt-row {
      td.cc-checkbox-locked {
        background-color: var(--cc-grid-col-even-sticky-checkbox-bg);
      }
      td.cc-background-color-locked {
        background-color: var(
          --cc-grid-col-even-sticky-no-checkbox-bg
        ) !important;
      }
      td:not(.k-grid-content-sticky):not(.k-group-cell) {
        background-color: var(--cc-grid-col-even-bg);
      }
    }
    /* ====  / EVEN COLUMN  ==== */
    // IS SELECTED
    &.k-selected {
      td.cc-checkbox-locked {
        background-color: var(--cc-grid-selected-col-sticky-checkbox-bg);
      }
      td.cc-background-color-locked {
        background-color: var(
          --cc-grid-selected-col-sticky-no-checkbox-bg
        ) !important;
      }
      td:not(.k-grid-content-sticky):not(.k-group-cell) {
        background-color: var(--cc-grid-selected-col-bg);
      }
    }
    // HOVER
    &:not(.k-selected):hover {
      td.cc-checkbox-locked {
        background-color: var(--cc-grid-col-sticky-checkbox-bg-hover);
      }
      td.cc-background-color-locked {
        background-color: var(--cc-grid-row-hover);
      }
      td:not(.k-grid-content-sticky):not(.k-group-cell) {
        background-color: var(--cc-grid-row-hover);
      }
    }
    /* ====  / Col sticky  ==== */
  }
}
/* ====  / UPGRADE KENDO NEW VERSION ^5.11.0  ==== */
