.cc-tooltip-validator {
  .k-tooltip {
    @apply border-l-4 border-error rounded-sm shadow-sm text-sm p-2 #{!important};
  }
  .cc-validator-result {
    @apply block  text-error font-semibold;
  }
}
.cc-tooltip-icon {
  @apply align-top text-error ml-1 transform -translate-y-1 #{!important};
  font-size: 0.4rem !important ;
  height: 100% !important;
}
