.cc-result {
  @apply flex flex-col;
  @apply overflow-y-auto;
  min-height: 260px;
  @apply justify-center items-center space-y-6;
  .cc-result-icon {
    @apply text-7xl;
  }
  & > h2 {
    @apply text-2xl text-component font-normal text-center;
  }
  & > h6 {
    @apply text-xl text-component font-light text-center;
  }

  &.cc-result-info {
    .cc-result-icon {
      @apply text-accent;
    }
  }
  &.cc-result-error {
    .cc-result-icon {
      @apply text-error;
    }
  }
  &.cc-result-success {
    .cc-result-icon {
      @apply text-success;
    }
  }
  &.cc-result-warning {
    .cc-result-icon {
      @apply text-warning;
    }
  }
}
#root {
  > .cc-result {
    @apply h-full;
  }
}
