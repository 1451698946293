.cc-group-title-label {
  @apply mb-6;
}

.cc-group-switch {
  @apply flex gap-x-8 gap-y-2 items-center flex-wrap;
}

.cc-label.cc-custom-mb {
  @apply mb-1;
}

.cc-contact:not(:last-of-type)::after {
  content: ", ";
}

.cc-form .cc-field-group .cc-tool-tip-wrapper.cc-group-label {
  @apply flex justify-start;
}

.cc-tool-tip-wrapper.cc-group-label .cc-icon-label-button.cc-color-button {
  color: #757575;
}

.cc-text-ellipsis {
  @apply truncate block;
}

.cc-field .cc-text-red {
  @apply text-error;
}
