.cc-multi-select-search {
  min-width: 600px;
  @apply py-0 overflow-x-scroll #{!important};
  .k-popup {
    min-width: 600px;
  }

  .k-list-header .cc-search-header {
    @apply grid grid-cols-3 bg-secondary w-full p-0;

    & > div {
      @apply p-2;
    }
  }
  .cc-search-item {
    @apply grid grid-cols-3 w-full;
    & > div {
      @apply p-2 break-words;
    }
  }

  .k-list-content .k-list-ul .k-list-item {
    @apply p-0;
  }
}

@media (max-width: 1600px) {
  .cc-multi-select-search {
    min-width: 600px;
  }
}

@media (max-width: 1000px) {
  .cc-multi-select-search {
    min-width: 400px;
  }
}

@media (max-width: 350px) {
  .cc-multi-select-search {
    min-width: 270px;
  }
}
