.cc-action-bar-nav-dropdown {
  @apply flex items-center flex-wrap;
  @apply md:flex-row flex-col;
  @apply md:space-x-1 space-x-0 md:space-y-0 space-y-1;
  @apply xl:w-auto w-full;
  @apply ml-1 md:mt-0 #{!important};

  .cc-navbar-dropdown {
    @apply bg-transparent md:w-auto w-full #{!important};
    min-width: 160px;
    max-width: none !important;
    .k-input,
    .k-icon {
      @apply text-accent text-sm #{!important};
    }
    .k-input {
      @apply truncate block leading-4 #{!important};
    }
    .k-dropdown-wrap {
      @apply bg-transparent #{!important};
    }
  }
}

@media (min-width: 768px) {
  .cc-action-bar-nav-dropdown {
    .cc-navbar-dropdown {
      max-width: 300px !important;
    }
  }
}
