.cc-product-ref-row {
  @apply grid grid-cols-5 gap-x-2;
  .cc-overflow-dot {
    @apply overflow-hidden overflow-ellipsis relative;
  }
  .cc-product-ref-title {
    @apply col-span-2;
    .cc-custom-tooltip {
      @apply w-full h-full absolute;
    }
  }
  .cc-product-ref-value {
    @apply col-span-3 font-medium;
    @apply break-words whitespace-normal;
  }
  .cc-product-ref-value-color-accent {
    @apply text-accent;
  }
  .cc-product-ref-value-color-error {
    @apply text-error;
  }
  .cc-product-ref-value-color-success {
    @apply text-success;
  }
  .cc-product-ref-value-color-warning {
    @apply text-warning;
  }
  .cc-product-ref-value-color-disabled {
    @apply text-disabled;
  }
  .cc-format-currency,
  .cc-format-number {
    @apply text-right;
  }
}

.cc-product-ref-badge {
  @apply font-medium p-2 text-center rounded border;
}
.cc-product-ref-badge-small {
  @apply font-normal px-1 text-sm text-center rounded mr-2 mb-1 text-white overflow-hidden #{!important};
  padding-top: 2px !important;
  padding-bottom: 3px !important;
}
.cc-product-ref-badge-color-accent {
  @apply text-accent bg-blue-500 bg-opacity-10 border-accent;
}
.cc-product-ref-badge-color-error {
  @apply text-error bg-red-500 bg-opacity-10 border-error;
}
.cc-product-ref-badge-color-success {
  @apply text-success bg-green-500 bg-opacity-10 border-success;
}
.cc-product-ref-badge-color-warning {
  @apply text-warning bg-yellow-500 bg-opacity-10 border-warning;
}

.cc-product-ref-badge-color-disabled {
  @apply text-disabled bg-gray-500 bg-opacity-10 border-disabled;
}

.cc-product-ref-badge-small-color-accent {
  @apply bg-info;
}
.cc-product-ref-badge-small-color-error {
  @apply bg-error;
}
.cc-product-ref-badge-small-color-success {
  @apply bg-success;
}
.cc-product-ref-badge-small-color-warning {
  @apply bg-warning;
}

.cc-product-ref-image {
  @apply rounded mt-6 mb-2 border-2 object-cover w-full;
}
