.cc-app-notification-container {
  @apply flex flex-col justify-start items-start #{!important};
  @apply overflow-hidden;

  & > .k-animation-container {
    @apply flex flex-col justify-start items-start space-y-2 #{!important};
    @apply w-full z-0 #{!important};
    @apply overflow-x-hidden overflow-y-auto;
    max-height: 150px;
    scroll-behavior: smooth;

    & > .k-child-animation-container {
      @apply w-full #{!important};
    }

    &:has(:first-child) {
      min-height: 43px;
    }
  }
  .k-notification-container {
    @apply w-full m-0 #{!important};
  }
}

.cc-local-notifications {
  @apply pb-2;
}
//Custom for cc-form
.cc-form {
  & > .cc-app-notification-container {
    @apply mb-1;
  }
}
